import styled from 'styled-components'

export const ContentContainer = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding: 0;
	flex-grow: 1;
`
export const ContentWrapper = styled.div`
	display: flex;
	flex: 1;
	height: auto;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 0;
	margin-bottom: 180px;
	width: 30%;
`

export const ContentSplit = styled.div`
	height: 580px;
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	position: relative;
	margin-top: 20px;
	@media only screen and (min-width: 1200px) {
		display: flex;
	}
	@media only screen and (max-width: 968px) {
		flex-direction: column-reverse;
		height: auto;
	}
`
