import React, {useState} from "react";
import styled from "styled-components";
import {RoundCheckbox} from "../../atoms/RoundCheckbox";
import {DatePicker} from "antd";
import {ReactComponent as DownArrow} from "../../../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {ReactComponent as GreyClockIcon} from "../../../../../../assets/backOffice/UtilIcon/GreyClockIcon.svg";
import {ReactComponent as ViewStaticIcon} from "../../../../../../assets/backOffice/UtilIcon/ViewStaticIcon.svg";
import {ReactComponent as ExportIcon} from "../../../../../../assets/backOffice/UtilIcon/ExportIcon.svg";
import {ReactComponent as ResetIcon} from "../../../../../../assets/backOffice/UtilIcon/ResetIcon.svg";
import {SelectItem} from "../../atoms/SelectItem";
import dayjs from "dayjs";
import {useHistory, useLocation} from "react-router-dom";
import {TimeSelectItem} from "../../atoms/TimeSelectItem";
import {setFilterState} from "../../../../../../utils/filterCash";


export const BottomFilter = ({timeItemValue, setTimeItemValue,   startTime,
                               setStartTime,
                               endTime,
                               setEndTime, filterReset, isReset}) => {
  const navigate = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const current = pathname.split('/admin')[1] || '';
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const TimeItem = {
    itemList: [
      {name: 'Today'},
      {name: 'Yesterday'},
      {name: 'Last 7 Days'},
      {name: 'Last 30 Days'},
      {name: 'This Month'},
      {name: 'Last Month'},
    ],
    value: timeItemValue,
    setValue: setTimeItemValue,
    title: 'Feature Buy'
  }

  return (
    <Molecule>
      <TitleWrapper>
        <Title>Date</Title>

        <Title style={{color: '#141C26'}}>UTC:8</Title>

        <RoundCheckbox checked={checked}
                       onChange={handleCheckboxChange}
                       text={"Start"}/>

        <RoundCheckbox checked={checked}
                       onChange={handleCheckboxChange}
                       text={"End"}/>
      </TitleWrapper>


      <ItemsWrapper>
        <LeftItemsWrapper>
          <DataPickerWrapper>
            <StyledDataPicker
              suffixIcon={<IconSVG as={DownArrow}/>}
              value={startTime ? dayjs(startTime, 'YYYY-MM-DD') : null}
              onChange={(date) => {
                const FILTER_KEY = `${current}_startTime`

                setStartTime(date ? date.format('YYYY-MM-DD') : '')
                setFilterState(FILTER_KEY, date ? date.format('YYYY-MM-DD') : '');
              }}
              format="YYYY-MM-DD"
            />
            <StyledDataPicker
              suffixIcon={<IconSVG as={DownArrow}/>}
              value={endTime ? dayjs(endTime, 'YYYY-MM-DD') : null}
              onChange={(date) => {
                const FILTER_KEY = `${current}_endTime`
                setEndTime(date ? date.format('YYYY-MM-DD') : '')
                setFilterState(FILTER_KEY, date ? date.format('YYYY-MM-DD') : '');
              }}
              format="YYYY-MM-DD"
            />
          </DataPickerWrapper>

          <TimeSelectItem itemList={TimeItem.itemList} value={TimeItem.value} setValue={TimeItem.setValue}
                      icon={GreyClockIcon} width={190} isReset={isReset}/>

          {/*<ViewStatic>*/}
          {/*  <IconSVG as={ViewStaticIcon}/>*/}
          {/*  View Statistic*/}
          {/*</ViewStatic>*/}

        </LeftItemsWrapper>

        <RightItemsWrapper>
          <SearchButton>
            Search
          </SearchButton>

          <ExportButton onClick={() => navigate.push('/admin/exportList')}>
            <IconSVG as={ExportIcon}/>
            Export
          </ExportButton>

          <ResetButton onClick={() => filterReset()}>
            <IconSVG as={ResetIcon}/>
            Reset
          </ResetButton>

        </RightItemsWrapper>
      </ItemsWrapper>

    </Molecule>
  )
}

const Molecule = styled.div`
    margin-top: 18px;
`

const TitleWrapper = styled.div`
    display: flex;
    margin-bottom: 4px;
`

const Title = styled.div`
    color: #6A6B70;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    margin-right: 16px;
`

const DataPickerWrapper = styled.div`

`

const StyledDataPicker = styled(DatePicker)`
    width: 160px;
    height: 44px;
`

const ItemsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const LeftItemsWrapper = styled.div`
    display: flex;
    gap: 10px;
`

const RightItemsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

const IconSVG = styled.svg`
    width: 20px;
`

const ViewStatic = styled.div`
    width: 160px;
    height: 44px;
    background: #F0F3F8;
    border: 1px solid #5D9DF480;
    color: #5D9DF480;
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-size: 16px;
    line-height: 19.09px;
    font-weight: 400;
`

const SearchButton = styled.div`
    width: 160px;
    height: 40px;
    box-shadow: 0px 1px 10px 0px #5D9DF480;
    background-color: #5D9DF4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    font-family: Pretendard, sans-serif;
`

const ExportButton = styled.div`
    width: 160px;
    height: 40px;
    border: 1px solid #5D9DF480;
    background-color: #F0F3F8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 6px;
    cursor: pointer;
    color: #5D9DF480;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    font-family: Pretendard, sans-serif;
`

const ResetButton = styled.div`
    width: 160px;
    height: 40px;
    box-shadow: 0px 1px 10px 0px #0000001F;
    border: 1px solid #5D9DF4;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 6px;
    cursor: pointer;
    color: #5D9DF4;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    font-family: Pretendard, sans-serif;
`