import React, {useState} from 'react'
import styled from "styled-components";
import {ReactComponent as HideFilterIcon} from "../../../../assets/backOffice/UtilIcon/HideFilterIcon.svg";
import {ReactComponent as CurrencyIcon} from "../../../../assets/backOffice/UtilIcon/CurrencyIcon.svg";
import {ReactComponent as UpArrow} from "../../../../assets/backOffice/UtilIcon/UpArrow.svg";
import {ReactComponent as DownArrow} from "../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {Display} from "../../Main/styles";
import {TestFilter} from "./organisms/TestFilter";
import {TestTable} from "./organisms/TestTable";
import {getFilterState, getFilterTimeItemValueState} from "../../../../utils/filterCash";
import {useLocation} from "react-router-dom";

export const BackOfficeSetting = React.memo(() => {
  // Util
  const [isFilter, setIsFilter] = React.useState(true);
  const location = useLocation();
  const { pathname } = location;
  const current = pathname.split('/admin')[1] || '';

  // Filter
  const [jsonFilter, setJsonFilter] = useState({});

  // Bottom Filter States
  const [timeItemValue, setTimeItemValue] = React.useState(getFilterTimeItemValueState(`${current}_timeItemValue`));
  const [startTime, setStartTime] = React.useState(getFilterState(`${current}_startTime`));
  const [endTime, setEndTime] = React.useState(getFilterState(`${current}_endTime`));

  return(
    <Display>
      <TopUtilWrapper>
        <UtilItem onClick={() => setIsFilter(!isFilter)}>
          <UtilIconSVG as={HideFilterIcon} />
          Hide Filter
          <ArrowSVG as={isFilter ? UpArrow : DownArrow} />
        </UtilItem>
      </TopUtilWrapper>

      {isFilter &&
        <TestFilter
          timeItemValue={timeItemValue}
          setTimeItemValue={setTimeItemValue}
        />
      }

      <TestTable/>

    </Display>
  )
})

const TopUtilWrapper = styled.div`
    display: flex;
    gap: 12px;
`

const UtilItem = styled.div`
    width: 160px;
    height: 40px;
    background-color: #E4E7EC;
    padding-left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    
    color: #141C26;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
`

const UtilIconSVG = styled.svg`
    width: 20px;
    margin-right: 6px;
`

const ArrowSVG = styled.svg`
    margin-left: 11px;
`

