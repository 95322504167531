import styled from "styled-components";
import '../../assets/font.css'
import { Icon } from "../Main/styles";

export const SideBarWrapper = styled.div`
  width: 280px;
  background-color: #F1F4F9;
  color: #898989;
  display: flex;
  flex-direction: column;
  position: relative;
`
export const SideBarTitle = styled.div`
  width: 100%;
  height: 60px;
  font-size: 20px;
  letter-spacing: -5%;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #010101;
  font-weight: 700;
  board : 1px;
`
export const LoginBtn = styled.div`
  border: none;
  background-color: #df7777;
  color: #fff;
  font-family: Pretendard;
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s;
  &:hover {
    border: 1px solid #df7777;
    background-color: transparent;
    color: #df7777;
  }
`

export const MessageBtn = styled.div`
  border: none;
  background-color: #0c88ea;
  color: #fff;
  font-family: Pretendard;
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s;
  &:hover {
    border: 1px solid #0c88ea;
    background-color: transparent;
    color: #0c88ea;
  }
`
export const SideBarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
export const Menu = styled.div`
  width: 100%;
  height: 42px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  cursor: pointer;
  transition: all.3s;
  ${Icon} {
    color: #898989;
  }
  &:hover {
    background-color: #5D9DF4;
    color: #fff;
    ${Icon} {
      color: #fff;
    }
  }
  &.selected {
    background-color: #5D9DF4;
    color: #fff;
    ${Icon} {
      color: #fff;
    }
  }
`