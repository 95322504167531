import React from "react";
import styled from "styled-components";
import {Checkbox, Table} from "antd";
import {useTranslation} from "react-i18next";

export const TestTable = ({walletData}) => {

  const {t} = useTranslation();


  const dummy = [
    {
      ID: 1,
      Operator: 2,
      Customer: 'testId',
      Date: '2024-04-05',
      Cur: 'USD',
      Transfer_Amount: 10000,
      Balance_Before: 0,
      Balance_After: 10000,
      From: 'test1',
      To: 'test2',

    },
    {
      ID: 2,
      Operator: 2,
      Customer: 'testId',
      Date: '2024-04-05',
      Cur: 'USD',
      Transfer_Amount: 10000,
      Balance_Before: 0,
      Balance_After: 10000,
      From: 'test1',
      To: 'test2',

    },
    {
      ID: 3,
      Operator: 2,
      Customer: 'testId',
      Date: '2024-04-05',
      Cur: 'USD',
      Transfer_Amount: 10000,
      Balance_Before: 0,
      Balance_After: 10000,
      From: 'test1',
      To: 'test2',

    },
  ]


  const columns = [
    {
      dataIndex: 'ID',
      title: <TableHeader>No.</TableHeader>,
      render: (value, data, index) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Operator',
      title: <TableHeader>{t('affiliatedAgency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Customer',
      title: <TableHeader>{t('userId')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Date',
      title: <TableHeader>{t('date')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Cur',
      title: <TableHeader>{t('transactionCurrency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Transfer_Amount',
      title: <TableHeader>{t('transferAmount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Balance_Before',
      title: <TableHeader>{t('beforeBalance')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Balance_After',
      title: <TableHeader>{t('afterBalance')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'From',
      title: <TableHeader>{t('from')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'To',
      title: <TableHeader>{t('to')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    }
  ];


  return (
    <Oraganism>

      <StyledTable columns={columns} dataSource={walletData && dummy}/>

    </Oraganism>
  )
}

const Oraganism = styled.div`

`

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #BAC4DD99;
        color: #141C26CC;
        font-weight: bold;
        border: none;
    }

    .ant-table-thead > tr > th::before {
        display: none;
    }
`;

const TableHeader = styled.div`
    text-align: center;
`

const ValueItem = styled.div`
    text-align: center;
`