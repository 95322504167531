export const setFilterState = (key, value) => {
  localStorage.setItem(key, value);
}

export const getFilterState = (key) => {
  return localStorage.getItem(key) || 'All';
}

export const resetFilterState = (key) => {
  localStorage.setItem(key, 'All');
}


export const getFilterTimeItemValueState = (key) => {
  return localStorage.getItem(key) || 'Today';
}