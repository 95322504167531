import React from "react";
import styled from "styled-components";
import {SelectItem} from "../atoms/SelectItem";
import {Select} from "antd";
import {TopFilter} from "../molecules/TestFilter/TopFilter";
import {BottomFilter} from "../molecules/TestFilter/BottomFilter";

export const TestFilter = (
  {
    timeItemValue,
    setTimeItemValue
  }
) => {



  return(
    <Oraganism>

        <BottomFilter
          timeItemValue={timeItemValue}
          setTimeItemValue={setTimeItemValue}
        />

    </Oraganism>
  )
}

const Oraganism = styled.div`
    margin-top: 12px;
`
