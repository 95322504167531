import React, {useState} from 'react';
import styled from "styled-components";
import {Checkbox, Col, Row, Select, Input} from "antd";
import {ReactComponent as DownArrow} from "../../../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {ReactComponent as UpArrow} from "../../../../assets/backOffice/UtilIcon/UpArrow.svg";
import {DownOutlined, UpOutlined} from "@ant-design/icons";

interface SelectItemProps {
  title?: string;
  value: string;
  setValue:  React.Dispatch<React.SetStateAction<string>>;
  itemList: itemList[];
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  width?:number;
}

interface itemList {
  name: string;
}


const { Search } = Input;

export const CustomerInputItem = ({title, value='', setValue, itemList = [{name: ''}], icon, width }:any) => {


  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);


  const handleSelectClick = () => {
    setOpen(!open);
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedValues(checkedValues);
  };

  return(
    <Atom>

      {title && <Title>{title}</Title>}

      {/*{icon && <IconSVG as={icon} />}*/}

      <InputWrapper>
        <StyleInput
          value={selectedValues.join(', ')}
          placeholder="Select options"
          suffix={<div onClick={handleSelectClick} style={{border: '1px solid black', width: 18, height: 18, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', cursor: 'pointer'}} >E</div>}
        >
        </StyleInput>
        {open && (
          <OpenInput>
            <Checkbox.Group style={{width: '100%', display:'flex', flexDirection: 'column'}} onChange={handleCheckboxChange}>
              <SelectText>Search for:</SelectText>
              <Checkbox >Exact Match</Checkbox>
              <Checkbox >Match Prefix</Checkbox>
            </Checkbox.Group>
          </OpenInput>
        )}
      </InputWrapper>

      {/*<StyledSelect*/}
      {/*  style={{width}}*/}
      {/*  value={value}*/}
      {/*  onChange={(e: string) => setValue(e)}*/}
      {/*  suffixIcon={<ArrowSVG as={DownArrow}/>}*/}
      {/*>*/}
      {/*  /!*<StyledOption value={'all'}>All</StyledOption>*!/*/}
      {/*  {itemList?.map(it => (*/}
      {/*    <StyledOption value={it.name}>{it.name}</StyledOption>*/}
      {/*  ))}*/}
      {/*</StyledSelect>*/}
    </Atom>
  )
}

const Atom = styled.div`
   
`

const Title = styled.div`
    color: #6A6B70;
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    margin-bottom: 4px;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const IconSVG = styled.svg`
    width: 20px;
    position: absolute;
    z-index: 1;
    margin-top: 12px;
    margin-left: 18px;
`

const StyledSelect = styled(Select)`
    width: 160px;
    height: 44px;
    box-shadow: 0px 1px 10px 0px #0000001F;
`

const StyledOption = styled(Select.Option)`
    color: #6A6B70;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.09px;
    font-family: Pretendard, sans-serif;
    padding-left: 100px;

`

const InputWrapper = styled.div`
    position: relative;
`

const StyleInput = styled(Input)`
    width: 160px;
    height: 44px;
    box-shadow: 0px 1px 10px 0px #0000001F;
`

const OpenInput = styled.div`
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 4px;
    margin-top: 2px;
    position: absolute;
    background-color: #FFFFFF;
    z-index: 999;
    width: 160px;
`

const SelectText = styled.div`
    font-size: 12px;
    color: darkgrey;
    font-style: italic;
`