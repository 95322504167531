const LOCAL_USE_INFO = 'LOCAL@USER_INFO'



export const setUserInfoItem = (item) => {

  localStorage.setItem(LOCAL_USE_INFO, JSON.stringify(item));
}


export const deleteUserInfoItem = () => {
  localStorage.setItem(LOCAL_USE_INFO, JSON.stringify(''));
}


export const getUserInfoItem = () => {
  return JSON.parse(localStorage.getItem(LOCAL_USE_INFO) || '[]')
}