import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {ReactComponent as HideFilterIcon} from "../../../../assets/backOffice/UtilIcon/HideFilterIcon.svg";
import {ReactComponent as CurrencyIcon} from "../../../../assets/backOffice/UtilIcon/CurrencyIcon.svg";
import {ReactComponent as UpArrow} from "../../../../assets/backOffice/UtilIcon/UpArrow.svg";
import {ReactComponent as DownArrow} from "../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {Display} from "../../Main/styles";
import {TestFilter} from "./organisms/TestFilter";
import {TestTable} from "./organisms/TestTable";
import {useToken} from "../../../../app/hooks";
import dayjs from "dayjs";
import {getUserInfoItem} from "../../../../utils/localUser";
import API from "../../../../utils/api";
import {getLocalCurrency, getLocalLang} from "../../../../utils/localLangCurrency";
import {getFilterState, getFilterTimeItemValueState, setFilterState} from "../../../../utils/filterCash";
import {useLocation} from "react-router-dom";

export const EventManage = React.memo(() => {
  // Util
  const [isFilter, setIsFilter] = React.useState(true);
  const location = useLocation();
  const { pathname } = location;
  const current = pathname.split('/admin')[1] || '';

  // Filter
  const [jsonFilter, setJsonFilter] = useState({});

  // Bottom Filter States
  const [timeItemValue, setTimeItemValue] = React.useState(getFilterTimeItemValueState(`${current}_timeItemValue`));
  const [startTime, setStartTime] = React.useState(getFilterState(`${current}_startTime`));
  const [endTime, setEndTime] = React.useState(getFilterState(`${current}_endTime`));

  // TableData
  const [eventData, setEventData] = React.useState([]);

  // Data
  const { data: token } = useToken()

  useEffect(() => {
    const today = dayjs();
    let start;
    let end;

    switch (timeItemValue) {
      case 'Today':
        start = today;
        end = today;
        break;
      case 'Yesterday':
        start = today.subtract(1, 'day');
        end = today.subtract(1, 'day');
        break;
      case 'Last 7 Days':
        start = today.subtract(7, 'day');
        end = today;
        break;
      case 'Last 30 Days':
        start = today.subtract(30, 'day');
        end = today;
        break;
      case 'This Month':
        start = today.startOf('month');
        end = today.endOf('month');
        break;
      case 'Last Month':
        start = today.subtract(1, 'month').startOf('month');
        end = today.subtract(1, 'month').endOf('month');
        break;
      default:
        start = today;
        end = today;
        break;
    }

    setStartTime(start.format('YYYY-MM-DD'));
    setFilterState(`${current}_startTime`, start.format('YYYY-MM-DD'));
    setEndTime(end.format('YYYY-MM-DD'));
    setFilterState(`${current}_endTime`, end.format('YYYY-MM-DD'));
  }, [timeItemValue]);


  useEffect(() => {
    postEvent();
  }, []);


  // BO-004-001 (이벤트관리)
  const postEvent = async () => {
    const userInfo = getUserInfoItem();
    const lang = getLocalLang();
    const currency = getLocalCurrency()

    try {
      const res = await API.send('POST', '/back-office/Tournament/GetEvents', token+'', {
        el: '', // 이벤트
        eo: '', // 옵션 (all, sub, name)
        lang: lang, // 표시언어
        op: userInfo.oid, // 에이전시 고유 번호로 변경 필요
        tc: currency, //거래 통화
        em: '', //  이벤트 종류
        st: '', // 상태
        fct : startTime,
        tct: endTime,
        ob: '', // 시간 조건 (Started, Created)
        sb: '', // 정렬조건 (asc, dec)
        utcto: ''
      })

      if (res.dt) {
        setEventData(res.dt)
      } else {
        console.log('ERROR 이벤트관리', res?.err)
      }
    } catch (e) {
      console.log(e, 'ERROR 이벤트관리')
    }
  }


  return(
    <Display>
      <TopUtilWrapper>
        <UtilItem onClick={() => setIsFilter(!isFilter)}>
          <UtilIconSVG as={HideFilterIcon} />
          Hide Filter
          <ArrowSVG as={isFilter ? UpArrow : DownArrow} />
        </UtilItem>

        <UtilItem>
          <UtilIconSVG as={CurrencyIcon} />
          Currency
        </UtilItem>
      </TopUtilWrapper>

      {isFilter &&
        <TestFilter
          setJsonFilter={setJsonFilter}
          jsonFilter={jsonFilter}

          timeItemValue={timeItemValue}
          setTimeItemValue={setTimeItemValue}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
        />
      }

      <TestTable eventData={eventData}/>

    </Display>
  )
})

const TopUtilWrapper = styled.div`
    display: flex;
    gap: 12px;
`

const UtilItem = styled.div`
    width: 160px;
    height: 40px;
    background-color: #E4E7EC;
    padding-left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    
    color: #141C26;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
`

const UtilIconSVG = styled.svg`
    width: 20px;
    margin-right: 6px;
`

const ArrowSVG = styled.svg`
    margin-left: 11px;
`

