import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import * as PAGE from './pagesImport'
import { GlobalStyle } from 'styles/global-styles'
import { NotFoundPage } from './components/NotFoundPage/Loadable'
import { useToken } from './hooks'
import { LogoutPage } from './pages/AuthenticationPages/LogoutPage/index'
import { CRM } from '../BackOffice/organism/Main'

export function Routes() {
	const { data: token } = useToken()

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/">
					{token ? <Redirect to="/admin" /> : <PAGE.LoginPage />}
				</Route>
				<Route path="/admin/login" component={token ? CRM : PAGE.LoginPage} />
				<Route path="/admin/logout" component={LogoutPage} />
				<Route path="/admin" component={token ? CRM : PAGE.LoginPage} />
				<Route path="*" component={NotFoundPage} />
			</Switch>
			<GlobalStyle />
		</BrowserRouter>

	)
}
