import React, { useEffect, useState } from 'react'
import { Main, Wrapper } from './styles'
import SideBar from '../SideBar'
import {useHistory, useLocation} from 'react-router-dom'
import TopBar from 'BackOffice/components/TopBar'
import { LEVEL, useMyInfo } from 'BackOffice/hooks/GetMyInfo'
import { SideBarTitle } from '../SideBar/styles'
import { useIsMobile } from "../../components/IsMobile";
import { View } from 'styles/reactnative'
import {UserInfoCard} from "../../components/UserInfoCard";
import {Alarm} from "../BackOfficeOrganism/Alarm";
import {StatisticSummary} from "../BackOfficeOrganism/Statistic/StatisticSummary";
import {DailyStatistics} from "../BackOfficeOrganism/Statistic/DailyStatistics";
import {HourlyStatistics} from "../BackOfficeOrganism/Statistic/HourlyStatistics";
import {EventManage} from "../BackOfficeOrganism/EventManage";
import {SpecialItem} from "../BackOfficeOrganism/SpecialItem";
import {WalletManage} from "../BackOfficeOrganism/WalletManage";
import {UserManage} from "../BackOfficeOrganism/UserManage";
import {ExportList} from "../BackOfficeOrganism/ExportList";
import {BackOfficeSetting} from "../BackOfficeOrganism/BackOfficeSetting";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {ReactComponent as TabCloseSVG} from "../../../assets/backOffice/UtilIcon/TabClose.svg";
import {ReactComponent as ActiveTabCloseSVG} from "../../../assets/backOffice/UtilIcon/ActiveTabClose.svg";
import {deleteTabItem, getTabItem} from "../../../utils/winTab";
import {Manager} from "../BackOfficeOrganism/Manager";

export const CRM = () => {
  const isMobile = useIsMobile();
  const navigate = useHistory()
  const location = useLocation()
  const { pathname } = location
  const current = pathname.split('/admin')[1] || '';
  const [view, setView] = useState(true)
  const [isUserInfoCard, setIsUserInfoCard] = useState(false)

  const [menu, setMenu] = useState<any>([]);
  const { t } = useTranslation();


  useEffect(() => {
    isMobile && setView(false)
  }, [isMobile]);

  useEffect(() => {
    setMenu([
      { name: 'Dashboard', url: '' },
      { name: t('alarm'), url: '' },
      { name: t('summary'), url: '/statisticSummary' },
      { name: t('dailyStatistics'), url: '/dailyStatistics' },
      { name: t('hourly'), url: '/hourlyStatistics' },
      { name: t('eventManage'), url: '/eventManage' },
      { name: t('specialItem'), url: '/special' },
      { name: t('walletManage'), url: '/walletManage' },
      { name: t('userManage'), url: '/userManage' },
      { name: t('exportList'), url: '/exportList' },
      { name: t('setting'), url: '/setting' },
      { name: t('manager'), url: '/manager' }
    ]);
  }, [t]);

  // useEffect(() => {
  //   console.log('view', view)
  // }, [view]);


  return (
    <View style={{
      flex: 1, width: '100%'
    }}>
      <TopBar view={view} setView={setView} setIsUserInfoCard={setIsUserInfoCard} isUserInfoCard={isUserInfoCard} />

      {isUserInfoCard &&
          <UserInfoCard isUserInfoCard={isUserInfoCard} />
      }

      <Main>
        <SideBar current={current} view={view} setView={setView} />
        <Wrapper>

          <WinTabSection>
            {getTabItem()?.map((item, index) => (
              <TabItem onClick={() => navigate.push( '/admin' + item.url)} isActivate={current === item.url}>
                {item?.name}
                <CloseTabItem as={current === item.url ? ActiveTabCloseSVG : TabCloseSVG} onClick={() => deleteTabItem(item.url)}/>
              </TabItem>
            ))}
          </WinTabSection>

          <div
            onClick={() => {
              isMobile && setView(false);
            }}
            style={{ paddingTop: isMobile ? 50 : 0 }}
          >
            {current === '' && <Alarm />}
            {current === '/statisticSummary' && <StatisticSummary />}
            {current === '/dailyStatistics' && <DailyStatistics />}
            {current === '/hourlyStatistics' && <HourlyStatistics />}
            {current === '/eventManage' && <EventManage />}
            {current === '/special' && <SpecialItem />}
            {current === '/walletManage' && <WalletManage />}
            {current === '/userManage' && <UserManage />}
            {current === '/exportList' && <ExportList />}
            {current === '/setting' && <BackOfficeSetting />}
            {current === '/manager' && <Manager />}



            {menu.filter((item: any) => item.url === current).length === 0 && (
              <SideBarTitle>⛔접근권한이 없습니다.</SideBarTitle>
            )}
          </div>


        </Wrapper>
      </Main>
    </View>
  )
}

export default React.memo(CRM)


const WinTabSection = styled.div`
    width: 100%;
    background-color: #DFE4EA;
    height: 68px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    gap: 12px;
`

const TabItem = styled.div<{isActivate:boolean}>`
    height: 40px;
    padding: 10.5px 12px;
    background-color: ${({isActivate}) => isActivate ? '#5D9DF4' : '#FFFFFF'};
    color: ${({isActivate}) => isActivate ? '#FFFFFF' : '#141C26'};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${({isActivate}) => isActivate ? '0px 1px 10px 0px #5D9DF480' : '0px 1px 10px 0px #0000001F'};
    cursor: pointer;
    border-radius: 4px;
`

const CloseTabItem = styled.svg`
    width: 16px;
    margin-left: 10px;
    cursor: pointer;
`