import React from "react";
import styled from "styled-components";
import {Checkbox, Table} from "antd";
import {useTranslation} from "react-i18next";

export const TestTable = ({eventData}) => {


  const { t } = useTranslation();


  const dummy = [
    {
      id: 1,
      name: "Test Event",
      st: '2024-08-30',
      et: '2025-01-01',
      op: "TestAgency",
      cur: 'KWR',
      sub: 'test',
      status: 'OPEN',
      mode: '-',
      csc: 20,
      ct: '2024-08-12',
      action: '-'
    },
    {
      id: 2,
      name: "Test Event2",
      st: '2024-08-30',
      et: '2025-01-01',
      op: "TestAgency",
      cur: 'KWR',
      sub: 'test',
      status: 'OPEN',
      mode: '-',
      csc: 11,
      ct: '2024-08-12',
      action: '-'
    },
    {
      id: 3,
      name: "Test Event3",
      st: '2024-08-30',
      et: '2025-01-01',
      op: "TestAgency",
      cur: 'USD',
      sub: 'test',
      status: 'OPEN',
      mode: '-',
      csc: 12,
      ct: '2024-08-12',
      action: '-'
    },
  ]

  const columns = [
    {
      dataIndex: 'id',
      title: <TableHeader>No.</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'name',
      title: <TableHeader>{t('eventName')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'st',
      title: <TableHeader>{t('startTime')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'et',
      title: <TableHeader>{t('endTime')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'op',
      title: <TableHeader>{t('applicableAgencyName')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'cur',
      title: <TableHeader>{t('transactionCurrency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'sub',
      title: <TableHeader>{t('subEventId')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'status',
      title: <TableHeader>{t('status')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'mode',
      title: <TableHeader>{t('transactionMode')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'csc',
      title: <TableHeader>{t('appliedCustomersCount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'ct',
      title: <TableHeader>{t('creationDate')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'action',
      title: <TableHeader>{t('detail')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
  ];


  return (
    <Oraganism>

      <StyledTable columns={columns} dataSource={eventData && dummy}/>

    </Oraganism>
  )
}

const Oraganism = styled.div`

`

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #BAC4DD99;
        color: #141C26CC;
        font-weight: bold;
        border: none;
    }

    .ant-table-thead > tr > th::before {
        display: none;
    }
`;

const TableHeader = styled.div`
    text-align: center;
`

const ValueItem = styled.div`
    text-align: center;
`