import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {ReactComponent as HideFilterIcon} from "../../../../../assets/backOffice/UtilIcon/HideFilterIcon.svg";
import {ReactComponent as CurrencyIcon} from "../../../../../assets/backOffice/UtilIcon/CurrencyIcon.svg";
import {ReactComponent as UpArrow} from "../../../../../assets/backOffice/UtilIcon/UpArrow.svg";
import {ReactComponent as DownArrow} from "../../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {Display} from "../../../Main/styles";
import {TestFilter} from "./organisms/TestFilter";
import {TestTable} from "./organisms/TestTable";
import dayjs from "dayjs";
import {getUserInfoItem} from "../../../../../utils/localUser";
import API from "../../../../../utils/api";
import {useToken} from "../../../../../app/hooks";
import {getFilterState, getFilterTimeItemValueState} from "../../../../../utils/filterCash";
import {useLocation} from "react-router-dom";

export const HourlyStatistics = React.memo(() => {
  // Util
  const [isFilter, setIsFilter] = React.useState(true);
  const location = useLocation();
  const { pathname } = location;
  const current = pathname.split('/admin')[1] || '';

  // Filter
  const [jsonFilter, setJsonFilter] = useState({});


  // Bottom Filter States
  const [timeItemValue, setTimeItemValue] = React.useState(getFilterTimeItemValueState(`${current}_timeItemValue`));
  const [startTime, setStartTime] = React.useState(getFilterState(`${current}_startTime`));
  const [endTime, setEndTime] = React.useState(getFilterState(`${current}_endTime`));
  const [startHour, setStartHour] = React.useState("");
  const [endHour, setEndHour] = React.useState("");

  // TableData
  const [hourlyData, setHourlyData] = React.useState([]);

  // Data
  const { data: token } = useToken()


  useEffect(() => {
    const today = dayjs();
    let start;
    let end;

    switch (timeItemValue) {
      case 'Today':
        start = today;
        end = today;
        break;
      case 'Yesterday':
        start = today.subtract(1, 'day');
        end = today.subtract(1, 'day');
        break;
      case 'Last 7 Days':
        start = today.subtract(7, 'day');
        end = today;
        break;
      case 'Last 30 Days':
        start = today.subtract(30, 'day');
        end = today;
        break;
      case 'This Month':
        start = today.startOf('month');
        end = today.endOf('month');
        break;
      case 'Last Month':
        start = today.subtract(1, 'month').startOf('month');
        end = today.subtract(1, 'month').endOf('month');
        break;
      default:
        start = today;
        end = today;
        break;
    }

    setStartTime(start.format('YYYY-MM-DD'));
    setStartHour(start.format('HH:mm:ss'));
    setEndTime(end.format('YYYY-MM-DD'));
    setEndHour(end.format('HH:mm:ss'));
  }, [timeItemValue]);

  useEffect(() => {
    postHourlyStatistic()
  }, []);


  // BO-003-004 (시간통계)
  const postHourlyStatistic = async () => {
    const userInfo = getUserInfoItem();

    try {
      const res = await API.send('POST', '/back-office/Report/GetOperatorHandsSummaryHourlyReport', token+'', {
        op: userInfo.oid, // 에이전시 고유 번호로 변경 필요
        JSON_filter : jsonFilter,
        df : startTime,
        ft: endTime
      })

      if (res.dt) {
        setHourlyData(res.dt)
      } else {
        console.log('ERROR 요약 통계', res?.err)
      }
    } catch (e) {
      console.log(e, 'ERROR 요약 통계')
    }
  }

  return(
    <Display>
      <TopUtilWrapper>
        <UtilItem onClick={() => setIsFilter(!isFilter)}>
          <UtilIconSVG as={HideFilterIcon} />
          Hide Filter
          <ArrowSVG as={isFilter ? UpArrow : DownArrow} />
        </UtilItem>

        <UtilItem>
          <UtilIconSVG as={CurrencyIcon} />
          Currency
        </UtilItem>
      </TopUtilWrapper>

      {isFilter &&
        <TestFilter
          setJsonFilter={setJsonFilter}
          jsonFilter={jsonFilter}

          timeItemValue={timeItemValue}
          setTimeItemValue={setTimeItemValue}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          startHour={startHour}
          setStartHour={setStartHour}
          endHour={endHour}
          setEndHour={setEndHour}
        />
      }

      <TestTable hourlyData={hourlyData}/>

    </Display>
  )
})


const TopUtilWrapper = styled.div`
    display: flex;
    gap: 12px;
`

const UtilItem = styled.div`
    width: 160px;
    height: 40px;
    background-color: #E4E7EC;
    padding-left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    
    color: #141C26;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
`

const UtilIconSVG = styled.svg`
    width: 20px;
    margin-right: 6px;
`

const ArrowSVG = styled.svg`
    margin-left: 11px;
`
