import React, {useState} from "react";
import styled from "styled-components";
import {Display} from "../../Main/styles";
import {Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useTranslation} from "react-i18next";
import API from "../../../../utils/api";
import {useToken} from "../../../../app/hooks";


export const Manager = () => {
  const { t } = useTranslation()

  const [oid, setOid] = useState('');
  const [on, setOn] = useState('');
  const [mm, setMm] = useState('');

  // 데이터 조회
  const {data:token} = useToken()


  // 에이전시 등록
  const createAgency = async () => {

    try {
      const res = await API.send('POST', '/back-office/Player/CreateAgency', token+'', {
        oid,
        on,
        mm,
      })

      if (res.dt) {
        console.log('res.dt', res.dt)
        if(res.dt.success) {
          window.alert('Success Register')

          setOid('');
          setOn('');
          setMm('');
        }
      } else {
        console.log('ERROR 에이전시 등록', res?.err)
      }
    } catch (e) {
      console.log(e, 'ERROR 에이전시 등록')
    }
  }



  return(
    <Display>

      <ButtonWrapper>
        <SendButton onClick={() => createAgency()}>{t('register')}</SendButton>
      </ButtonWrapper>

      <InputWrapper>
        <InputLabel>
          {t('topAgencyId')}
        </InputLabel>
        <StyledInput value={oid} onChange={(e) => setOid(e.target.value)}/>
      </InputWrapper>

      <InputWrapper>
        <InputLabel>
          {t('agencyName')}
        </InputLabel>
        <StyledInput value={on} onChange={(e) => setOn(e.target.value)}/>
      </InputWrapper>

      <InputWrapper>
        <InputLabel>
          {t('memo')}
        </InputLabel>
        <StyledTextArea value={mm} onChange={(e) => setMm(e.target.value)}/>
      </InputWrapper>
    </Display>
  )
}




const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`


const SendButton = styled.div`
    width: 160px;
    height: 40px;
    background-color: #5D9DF4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 1px 10px 0px #5D9DF480;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    cursor: pointer;
`

const InputWrapper = styled.div`
  display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 20px;
`

const InputLabel = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    color: #6A6B70;
`

const StyledInput = styled(Input)`
    width: 30%;
`

const StyledTextArea = styled(TextArea)`
    width: 30%;
`

