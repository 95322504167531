import React from "react";
import styled from "styled-components";
import {Checkbox, Table} from "antd";
import {useTranslation} from "react-i18next";
import {ReactComponent as DownloadIcon} from "../assets/DownloadIcon.svg";

export const TestTable = ({exportData}) => {


  const dummy = [
    {
      ehid: 1,
      uid: 1234,
      un: 1,
      mod: 'Event Management',
      fnc: '',
      rdf: '2024-01-05',
      rdt: '2024-01-20',
      utcto: '',
      fn: '/public/excelName.xlsx',
      ft: 'zip'
    },
    {
      ehid: 2,
      uid: 1234,
      un: 1,
      mod: 'Event Management',
      fnc: '',
      rdf: '2024-01-05',
      rdt: '2024-01-20',
      utcto: '',
      fn: '/public/excelName.xlsx',
      ft: 'zip'
    },
    {
      ehid: 3,
      uid: 1234,
      un: 1,
      mod: 'Event Management',
      fnc: '',
      rdf: '2024-01-05',
      rdt: '2024-01-20',
      utcto: '',
      fn: '/public/excelName.xlsx',
      ft: 'zip'
    },
  ]


  const { t } = useTranslation();


  const columns = [
    {
      dataIndex: 'ehid',
      title: <TableHeader>No.</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'uid',
      title: <TableHeader>{t('userUniqueNumber')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'un',
      title: <TableHeader>{t('requestId')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'mod',
      title: <TableHeader>{t('exportRequestedTopmenu')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'fnc',
      title: <TableHeader>{t('exportRequestedSubmenu')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'rdf',
      title: <TableHeader>{t('searchRange')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'rdt',
      title: <TableHeader>{t('searchRange')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'utcto',
      title: <TableHeader>{t('standardTime')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'fn',
      title: <TableHeader>{t('filePath')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'ft',
      title: <TableHeader>{t('fileType')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: '',
      title: <TableHeader>{t('DownloadCSVFile')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>
            <DownloadIconSVG as={DownloadIcon}/>
          </ValueItem>
        )
      }
    },
  ];


  return (
    <Oraganism>

      <StyledTable columns={columns} dataSource={ exportData ? exportData : dummy}/>

    </Oraganism>
  )
}

const Oraganism = styled.div`

`

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #BAC4DD99;
        color: #141C26CC;
        font-weight: bold;
        border: none;
    }

    .ant-table-thead > tr > th::before {
        display: none;
    }
`;

const TableHeader = styled.div`
    text-align: center;
`


const ValueItem = styled.div`
    text-align: center;
`

const DownloadIconSVG = styled.svg`
    width: 18px;
    height: 18px;
    cursor: pointer;
`