import React from "react";
import styled from "styled-components";
import {Checkbox, Table} from "antd";
import {useTranslation} from "react-i18next";

export const TestTable = ({playersListData}) => {

  const { t } = useTranslation();


  const dummy = [
    {
      id: 1,
      dt: "2024-02-20",
      oid: 1,
      cur: 'USD',
      Collapse_CT : 2,
      Collapse_FS : 1,
      Payment : "Card",
      Times : "2024-02-11",
      Try_Count : 0,
      Pay_Amount: 10000,
      Balance : 0,
      Company_BF : 0
    },
    {
      id: 2,
      dt: "2024-02-02",
      oid: 2,
      cur: 'USD',
      Collapse_CT : 2,
      Collapse_FS : 1,
      Payment : "Account",
      Times : "2024-02-11",
      Try_Count : 0,
      Pay_Amount: 10000,
      Balance : 0,
      Company_BF : 0
    },
    {
      id: 3,
      dt: "2024-02-01",
      oid: 1,
      cur: 'USD',
      Collapse_CT : 2,
      Collapse_FS : 1,
      Payment : "Card",
      Times : "2024-02-11",
      Try_Count : 0,
      Pay_Amount: 10000,
      Balance : 0,
      Company_BF : 0
    },

  ]

  const columns = [
    {
      dataIndex: 'id',
      title: <TableHeader>No.</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'dt',
      title: <TableHeader>{t('date')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'oid',
      title: <TableHeader>{t('affiliatedAgency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'cur',
      title: <TableHeader>{t('transactionCurrency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Collapse_CT',
      title: <TableHeader>{t('failedTransactionsCount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Collapse_FS',
      title: <TableHeader>{t('canceledTransactionsCount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Payment',
      title: <TableHeader>{t('transactionMethod')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Times',
      title: <TableHeader>{t('transactionDate')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Try_Count',
      title: <TableHeader>{t('retriesCount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Pay_Amount',
      title: <TableHeader>{t('amountOfPayment')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Balance',
      title: <TableHeader>{t('balance')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Company_BF',
      title: <TableHeader>{t('commissionIncome')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
  ];


  return (
    <Oraganism>

      <StyledTable columns={columns} dataSource={playersListData ? playersListData : dummy}/>

    </Oraganism>
  )
}

const Oraganism = styled.div`

`

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #BAC4DD99;
        color: #141C26CC;
        font-weight: bold;
        border: none;
    }

    .ant-table-thead > tr > th::before {
        display: none;
    }
`;

const TableHeader = styled.div`
    text-align: center;
`


const ValueItem = styled.div`
    text-align: center;
`