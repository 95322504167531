import React from "react";
import styled from "styled-components";
import {SelectItem} from "../../atoms/SelectItem";
import {CheckInputItem} from "../../../StatisticSummary/atoms/CheckInputItem";
import {useTranslation} from "react-i18next";
import {useFilterList} from "../../../../../../hooks/BackOffice/GetFilterList";

export const TopFilter = (
  {
    jsonFilter,
    setJsonFilter,
    isReset
  }
) => {

  const {filterList} = useFilterList();

  return (
    <Molecule>

      {filterList?.map((item, index) => {
        if((item.name === 'Manager') || (item.name === 'Currency')) {
          return (
            <CheckInputItem
              setJsonFilter={setJsonFilter}
              jsonFilter={jsonFilter}
              isReset={isReset}
              item={item}
              key={`SelectItem${index}`}/>
          )
        } else {
          return (
            <SelectItem
              setJsonFilter={setJsonFilter}
              jsonFilter={jsonFilter}
              item={item}
              key={`SelectItem${index}`}
              isReset={isReset}
            />
          )
        }
      })}


    </Molecule>
  )
}

const Molecule = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 0px));
    gap: 12px;
`