import React from "react";
import styled from "styled-components";
import {Checkbox, Table} from "antd";
import {useTranslation} from "react-i18next";

export const TestTable = ({hourlyData}) => {


  const { t } = useTranslation();


  const columns = [
    {
      dataIndex: '',
      title: <TableHeader>No.</TableHeader>,
      render: (value, data, index) => {
        return (
          <ValueItem>{index + 1}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'dt',
      title: <TableHeader>{t('date')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'oid',
      title: <TableHeader>{t('affiliatedAgency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'poid',
      title: <TableHeader>{t('topAgency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'cur',
      title: <TableHeader>{t('transactionCurrency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Collapse_CT',
      title: <TableHeader>{t('failedTransactionsCount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Collapse_FS',
      title: <TableHeader>{t('canceledTransactionsCount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Payment',
      title: <TableHeader>{t('transactionMethod')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Times',
      title: <TableHeader>{t('transactionDate')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Try_Count',
      title: <TableHeader>{t('retriesCount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Pay_Amount',
      title: <TableHeader>{t('amountOfPayment')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Balance',
      title: <TableHeader>{t('balance')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'Company_BF',
      title: <TableHeader>{t('commissionIncome')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
  ];


  return (
    <Oraganism>

      <StyledTable columns={columns} dataSource={hourlyData}/>

    </Oraganism>
  )
}

const Oraganism = styled.div`

`

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #BAC4DD99;
        color: #141C26CC;
        font-weight: bold;
        border: none;
    }

    .ant-table-thead > tr > th::before {
        display: none;
    }
`;

const TableHeader = styled.div`
    text-align: center;
`


const ValueItem = styled.div`
    text-align: center;
`