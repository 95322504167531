const TAB_ITEM_KEY = 'TAB@ITEM_KEY'


export const setTabItem = (item) => {
  const storedArray = JSON.parse(localStorage.getItem(TAB_ITEM_KEY) || '[]');

  const itemIndex = storedArray.findIndex(storedItem => storedItem.url === item.url);

  if (itemIndex !== -1) {
    // storedArray.splice(itemIndex, 1);
  } else {
    storedArray.push(item);
  }

  localStorage.setItem(TAB_ITEM_KEY, JSON.stringify(storedArray));
}


export const deleteTabItem = (url) => {
  const storedArray = JSON.parse(localStorage.getItem(TAB_ITEM_KEY) || '[]');

  const updatedArray = storedArray.filter(item => item.url !== url);

  localStorage.setItem(TAB_ITEM_KEY, JSON.stringify(updatedArray));
}


export const getTabItem = () => {
  const storedData = localStorage.getItem(TAB_ITEM_KEY) || '[]';
  try {
    const parsedData = JSON.parse(storedData);
    if (Array.isArray(parsedData)) {
      return parsedData;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export const resetTabItem = () => {
  localStorage.setItem(TAB_ITEM_KEY, JSON.stringify([]));
}
