import styled from "styled-components";
import '../../assets/font.css'
import {MobileCalVW} from "../../components/CalVW";

export const Main = styled.div`
  font-family: 'Pretendard';
  width: 100%;
  background-color: #FFF;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  display: flex;
  overflow: hidden;
`
export const Icon = styled.div`
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
`
export const Display = styled.div<{IsMobile?:boolean}>`
  background-color: #F0F3F8;
  width: ${(props) => props.IsMobile ? '100vw': '100%'};
  min-height: ${(props) => props.IsMobile ? `calc(100vh - ${MobileCalVW(50)});`: 'calc(100vh - 50px)'};
  height: auto;
  padding: ${(props) => props.IsMobile ? MobileCalVW(30): '30px'};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.IsMobile ? MobileCalVW(0): '16px'};
  box-shadow: 2px 2px 8px 2px #74747476;
  overflow: hidden;
  overflow-y: auto;
`