import { useWindowDimensions } from 'app/hooks'
import { useLocation } from 'react-router-dom'


export const checkEmail = email => {
	return true;
}


export const apiError = (t, code) => {
	if (code === 1) {
		return t('LANG_NO_INPUT')
	} else if (code === 2) {
		return t('LANG_NO_USER')
	} else if (code === 3) {
		return t('LANG_FAIL_PASSWORD')
	} else if (code === 4) {
		return t('LANG_EXIST_USER')
	} else if (code === 5) {
		return t('LANG_NO_INPUT_EMAIL')
	} else if (code === 6) {
		return t('LANG_NO_INPUT_PHONE')
	} else if (code === 7) {
		return t('LANG_ERROR')
	} else if (code === 8) {
		return t('LANG_INVALID_REFERRAL')
	} else {
		return t('LANG_ERROR')
	}
}