import React, {useState} from "react";
import styled from "styled-components";
import {SelectItem} from "../atoms/SelectItem";
import {Select} from "antd";
import {TopFilter} from "../molecules/TestFilter/TopFilter";
import {BottomFilter} from "../molecules/TestFilter/BottomFilter";
import {setFilterState} from "../../../../../../utils/filterCash";
import {useLocation} from "react-router-dom";

export const TestFilter = (
  {
    jsonFilter,
    setJsonFilter,
    timeItemValue,
    setTimeItemValue,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    startHour,
    setStartHour,
    endHour,
    setEndHour,
  }
) => {
    const location = useLocation();
    const { pathname } = location;
    const current = pathname.split('/admin')[1] || '';
    const [isReset, setIsReset] = useState(false);

    const filterReset = () => {
        setIsReset(!isReset);
        setFilterState(`${current}_startTime`, '');
        setFilterState(`${current}_endTime`, '');
        // setStartTime('');
        // setEndTime('');
    }

  return(
    <Oraganism>
      <TopFilter
        jsonFilter={jsonFilter}
        setJsonFilter={setJsonFilter}
        isReset={isReset}
      />

        <BottomFilter
          timeItemValue={timeItemValue}
          setTimeItemValue={setTimeItemValue}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          startHour={startHour}
          setStartHour={setStartHour}
          endHour={endHour}
          setEndHour={setEndHour}
          filterReset={filterReset}
          isReset={isReset}
        />

    </Oraganism>
  )
}

const Oraganism = styled.div`
    margin-top: 12px;
`
