// export const API_URL = 'http://220.127.194.187:3000/v1' // API URL

export const API_URL = 'https://api.pg-bonjour.com/v1' // API URL

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDWa6hlrMtPx2AvZgSR7HyYntuJ0l6XZ4A",
    authDomain: "cms-server-22b35.firebaseapp.com",
    projectId: "cms-server-22b35",
    storageBucket: "cms-server-22b35.appspot.com",
    messagingSenderId: "887007230308",
    appId: "1:887007230308:web:f5e9fe3faba37ae3c9a271"
};

export const VAPID_KEY="BBw0ryNkmuEGY1w6ZTsTvsINJIS-mbEANi4Y826-M7zW5iGfiOika787Okrt3uF5R8mdPFzTX0HucYpVYPpunhI"