import React from "react";
import styled from "styled-components";
import {Checkbox, Table} from "antd";
import {useTranslation} from "react-i18next";

export const TestTable = () => {


  const { t } = useTranslation();

  const columns = [
    {
      dataIndex: '',
      title: <TableHeader>No.</TableHeader>,
      render: (value, data) => {
        return (
          <></>
        )
      }
    },
    {
      dataIndex: '',
      title: <TableHeader>{t('APIName')}</TableHeader>,
      render: (value, data) => {
        return (
          <></>
        )
      }
    },
    {
      dataIndex: '',
      title: <TableHeader>ON/OFF</TableHeader>,
      render: (value, data) => {
        return (
          <></>
        )
      }
    }
  ];


  return (
    <Oraganism>

      <StyledTable columns={columns}/>

    </Oraganism>
  )
}

const Oraganism = styled.div`

`

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #BAC4DD99;
        color: #141C26CC;
        font-weight: bold;
        border: none;
    }

    .ant-table-thead > tr > th::before {
        display: none;
    }
`;

const TableHeader = styled.div`
    text-align: center;
`