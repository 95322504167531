import React, {useState, useEffect} from 'react'
import {TopBarWrapper} from './styles';
import {FaBars} from 'react-icons/fa6';
import {Icon} from 'BackOffice/organism/Main/styles';
import {useLocation} from 'react-router-dom';
import {useIsMobile} from "./IsMobile";
import {useToken} from 'app/hooks'
import styled from "styled-components";
import {useHistory} from 'react-router-dom'
import {ReactComponent as ClockIcon} from "../../assets/backOffice/TopNav/ClockIcon.svg";
import {ReactComponent as HumanIcon} from "../../assets/backOffice/TopNav/HumanIcon.svg";
import { useTranslation } from 'react-i18next';
import languageImage from "../../assets/images/languageImage.jpg";
import {Select} from "antd";
import backOfficeLogo from "../../assets/backOffice/backOfficeLogo.jpeg";
import {getLocalCurrency, getLocalLang, setLocalLang} from "../../utils/localLangCurrency";
import {resetTabItem} from "../../utils/winTab";

const options = [
  {value: 'EN', label: 'EN'},
  {value: 'KR', label: 'KR'},
  {value: 'CN', label: 'CN'}
];


export const TopBar = ({view, setView, setIsUserInfoCard, isUserInfoCard}) => {
    // const location = useLocation()
    // const {pathname} = location;
    // const current = pathname.split("/admin")[1]
    // const isMobile = useIsMobile();
    // const {data} = useToken()
    // const navigate = useHistory()


    const {  t, i18n } = useTranslation();
    const lang = getLocalLang();
    const currency = getLocalCurrency()
    const [currentTime, setCurrentTime] = useState(new Date());
    const [languageOption, setLanguageOption] = useState(lang);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // 1초마다 현재 시간 업데이트

        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 클리어
    }, []);

    const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };



    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        // console.log('i18n.resolvedLanguage', i18n.resolvedLanguage);
    };

  useEffect(() => {
    const currentLang = getLocalLang();

    if (languageOption !== currentLang) {
      if(languageOption === 'EN'){
        changeLanguage('en');
        setLocalLang('EN');
      } else if(languageOption === 'KR') {
        changeLanguage('ko');
        setLocalLang('KR');
      } else {
        changeLanguage('cn');
        setLocalLang('CN');
      }

      resetTabItem();  // 실제 언어가 변경되었을 때만 resetTabItem 호출
    }
  }, [languageOption]);

    const customSingleValue = (data) => (
      <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: '1 0 0',
          alignSelf: 'stretch'
      }}>
          <img
            src={`https://flagcdn.com/w20/${data === 'USD' ? 'us' : data === 'KRW' ? 'kr' : 'cn'}.png`}
            alt={data}
            style={{
                display: 'flex',
                alignItems: 'center',
                width: '20px',
                height: '15px',
                marginRight: '8px',
            }}
          />
          <p>{data}</p>
      </div>
    );


    return (
      <TopBarWrapper style={{width: '100%'}}>
          {/*<Icon*/}
          {/*  onClick={() => {*/}
          {/*      setView(!view);*/}
          {/*      console.log('TopBarWrapper', view)*/}
          {/*  }}>*/}
          {/*    <FaBars/>*/}
          {/*</Icon>*/}

          {/*{current === '' && t('alarm')}*/}
          {/*{current === '/statisticSummary' && t('summary')}*/}
          {/*{current === '/dailyStatistics' && t('dailyStatistics')}*/}
          {/*{current === '/hourlyStatistics' && t('hourly')}*/}
          {/*{current === '/eventManage' && t('eventManage')}*/}
          {/*{current === '/special' && t('specialItem')}*/}
          {/*{current === '/walletManage' && t('walletManage')}*/}
          {/*  {current === '/userManage' && t('userManage')}*/}
          {/*  {current === '/exportList' && t('exportList')}*/}
          {/*  {current === '/setting' && t('setting')}*/}

        <LoginLogoImg src={backOfficeLogo} alt={'LoginLogoImg_'}/>


            <TopUtilWrapper>
                <ClockWrapper>
                    <ClockIconSVG as={ClockIcon}/>
                    <TopUitlTime>{formatDateTime(currentTime)}</TopUitlTime>
                </ClockWrapper>
                <TopUitlCur>
                    {customSingleValue(currency)}
                </TopUitlCur>

                <RightUtilWrapper>
                    <HumanIconSVG as={HumanIcon} onClick={() => setIsUserInfoCard(!isUserInfoCard)}/>
                    {/*{data !== '' && (<LoginBtn style={{width: 70, textAlign: 'center'}}*/}
                    {/*                           onClick={() => navigate.push('/admin/logout')}>로그아웃</LoginBtn>)}*/}

                  <LgSelectBoxGroupWrap>
                    <LgImg>
                      <img style={{width: '20px', height: '18.18px'}} alt={'selected_language_Image'}
                                src={languageImage}></img>
                    </LgImg>

                    <LgSelectBox
                      options={options}
                      value={languageOption}
                      onChange={e => setLanguageOption(e)}
                      //placeholder="Select a country"
                      //classNamePrefix="country-select"
                    />

                  </LgSelectBoxGroupWrap>
                </RightUtilWrapper>

            </TopUtilWrapper>
        </TopBarWrapper>
    )
}

const LgSelectBox = styled(Select)`
    &&& {
        color: #3e4146;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        height: 100%;
        border: none;
        box-shadow: none;

        .ant-select-selector {
            border: none !important;
            box-shadow: none !important;
            height: 100%;
        }

        .ant-select-arrow {
            display: none;
        }
    }
`;

const LoginLogoImg = styled.img`
    width: 70px;
    margin-bottom: 5px;
`

const LgSelectBoxGroupWrap = styled.div`
    display: flex;
    width: 104px;
    height: 40px;
    padding-right: 4px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    background: #FFF;
`
const LgImg = styled.div`
    display: flex;
    padding: 10px;
    /*flex-direction: column;*/
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-right: 1px solid #D8D8D8;
`

export const LoginBtn = styled.div`
    border: none;
    background-color: #df7777;
    color: #fff;
    font-family: Pretendard;
    padding: 8px 12px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: .3s;

    &:hover {
        border: 1px solid #df7777;
        background-color: transparent;
        color: #df7777;
    }
`

export const TopUtilWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    align-self: stretch;
`
export const TopUitlTime = styled.p`
    color: #3E4146;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const TopUitlCur = styled.p`
    color: #3E4146;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

const HumanIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ClockIconSVG = styled.svg`

`

const ClockWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

const RightUtilWrapper = styled.div`
    display: flex;
    background-color: #F6F6F6;
    border-left: 1px solid #D8D8D8;
    padding: 0 30px;
    height: 50px;
    align-items: center;
    gap: 10px;
`

export default React.memo(TopBar);