import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../../app/hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/back-office/GetFilter'

let swrData: any = getPersistData(KEY)

export function useFilterList() {
	const { data: token } = useToken()

	const { data, mutate } = useSWR<any>(
		KEY,
		async (url) => {
			const response = await API.get(url, token + '', {})
			swrData = response?.dt

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		filterList: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}