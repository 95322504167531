import * as React from 'react'
import { ContentContainer, ContentWrapper } from '../../../../styles/login'
import LoginSection from 'app/organisms/Auth/LoginSection'
import { ThemeContext } from 'theme/ThemeProvider'
export function LoginPage() {
	const { isDarkMode } = React.useContext(ThemeContext)
	return (
		<ContentContainer isDarkMode={isDarkMode}>
			{/* <Header /> */}
			<ContentWrapper>
				<LoginSection />
			</ContentWrapper>
		</ContentContainer>
	)
}
