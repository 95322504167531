import React, {useEffect, useState} from 'react'
import {LoginBtn, Menu, MessageBtn, SideBarMenu, SideBarTitle, SideBarWrapper} from './styles'
import {useHistory} from 'react-router-dom'
import {Icon} from '../Main/styles'
import {FaChevronRight} from 'react-icons/fa6'
import {useToken} from 'app/hooks'
import {LEVEL, useMyInfo} from 'BackOffice/hooks/GetMyInfo'
import {message, Switch} from "antd";
import {useIsMobile} from "../../components/IsMobile";
import styled from "styled-components";
import {ReactComponent as UpGreyArrow} from "../../../assets/backOffice/SideNav/UpGreyArrow.svg";
import {ReactComponent as DownGreyArrow} from "../../../assets/backOffice/SideNav/DownGreyArrow.svg";
import {useTranslation} from "react-i18next";
import {setTabItem} from "../../../utils/winTab";
import {apiError, checkEmail} from "../../../utils/ExpensiveFunction";
import API from "../../../utils/api";
import {getUserInfoItem} from "../../../utils/localUser";

export const SideBar = ({current, view, setView}) => {
  const isMobile = useIsMobile();
  const {data:token} = useToken()
  const navigate = useHistory()
  const {  t } = useTranslation();

  const [menu, setMenu] = useState<any>([]);
  const [permissionMenu, setPermissionMenu] = useState<any>([]);
  const [menuIndex, setMenuIndex] = useState<any>(null);
  const [selectedMenu, setSelectedMenu] = useState<any>('Dashboard');


  // useEffect(() => {
  // 	console.log('menuIndex', menuIndex)
  // }, [menuIndex]);

  const menuClick = menu => {
    if (isMobile) {
      setView(false);
    }
    navigate.push('/admin' + menu)
  }


  // 사용자메뉴권한(after BO-001-001)
  const postMenuPermission = async () => {
    const userInfo = getUserInfoItem();

    try {
      const res = await API.send('POST', '/back-office/UserManagement/GetPermissionsByUserId', token+'', {
        uid:userInfo?.uid
      })

      if (res.dt) {
        setPermissionMenu(res.dt)
      } else {
        console.log('ERROR 사용자메뉴권한', res?.err)
      }
    } catch (e) {
      console.log(e, 'ERROR 사용자메뉴권한')
    }
  }



  useEffect(() => {
    setMenu([
      {
        name: t('alarm'),
        url: '',
        id: 1
      },
      {
        name: t('statistic'),
        id: 2,
        twoDeps: [
          {name: t('summary'), url: '/statisticSummary'},
          {name: t('dailyStatistics'), url: '/dailyStatistics'},
          {name: t('hourly'), url: '/hourlyStatistics'},
        ]
      },
      {
        name: t('eventManage'),
        url: '/eventManage',
        id: 3
      },
      {
        name: t('specialItem'),
        url: '/special',
        id: 4
      },
      {
        name: t('walletManage'),
        url: '/walletManage',
        id: 5
      },
      {
        name: t('userManage'),
        url: '/userManage',
        id: 6
      },
      {
        name: t('exportList'),
        url: '/exportList',
        id: 7
      },
      {
        name: t('setting'),
        url: '/setting',
        id: 8,
      },
      {
        name: t('manager'),
        url: '/manager',
        id: 9
      },

    ])

    postMenuPermission();
  }, [t])

  return (
    <SideBarWrapper style={{
      display: view ? 'block' : 'none',
      position: isMobile ? 'fixed' : 'relative',
      zIndex: isMobile ? 9999 : 0,
      height: isMobile ? '9999px' : '',
      borderRight: '1px solid #D8D8D8'
    }}>
      {/* <SideBarTitle> */}
      {/* 메뉴 */}
      {/* <div> */}
      {/* {data !== '' && ( */}
      {/* <div style={{display: "flex", alignItems: 'center'}}> */}
      {/* <LoginBtn onClick={() => navigate.push('/admin/logout')}>로그아웃</LoginBtn> */}
      {/* <MessageBtn style={{marginLeft: 5}} onClick={() => {setMessegeModal(true)}}>{`메세지함(${totalCount})`}</MessageBtn> */}
      {/* <Switch checkedChildren={'ON'} unCheckedChildren={'OFF'} size={'small'} style={{marginLeft: 5}} checked={messageSwitch} onChange={(checked) => setMessageSwitch(checked)}/> */}
      {/* </div> */}
      {/* )} */}
      {/* </div> */}
      {/* </SideBarTitle> */}

      {/*<CustomMenu*/}
      {/*  Active={selectedMenu === 'Dashboard'}*/}
      {/*  onClick={() => {*/}
      {/*    menuClick('');*/}
      {/*    setSelectedMenu('Dashboard')*/}
      {/*    setMenuIndex(null);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Dashboard*/}
      {/*</CustomMenu>*/}

      {/*<NavCategory>REPORTS</NavCategory>*/}

      <SideBarMenu>
        {menu?.map((item, index) => {
          return (
            <>
              <CustomMenu
                key={index}
                onClick={() => {
                  setMenuIndex(index === menuIndex ? null : index);

                  if(item.url !== undefined){
                    menuClick(item.url);
                    setTabItem(item)
                  }
                }}
              >
                {item.name}
                {item?.twoDeps &&  <ArrowSVG as={index === menuIndex ? UpGreyArrow : DownGreyArrow}/>}
              </CustomMenu>

              {index === menuIndex && item?.twoDeps?.map((item, index) => (
                <TwoDepsMenu
                  key={`TwoDepsMenu_${index}`}
                  Active={current === item.url}
                  onClick={() => {
                    menuClick(item.url);
                    setTabItem(item)
                    setSelectedMenu(item.name);
                  }}
                >
                  {item.name}
                </TwoDepsMenu>
              ))}
            </>
          )
        })}
      </SideBarMenu>
    </SideBarWrapper>
  )
}

export default React.memo(SideBar)


const NavCategory = styled.div`
    color: #CBCACF;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.71px;
    padding: 10px 0px 10px 20px;
    margin-top: 20px;
`

const TwoDepsMenu = styled.div<{ Active?: boolean }>`
    cursor: pointer;
    background-color: ${(props) => props.Active ? "#5D9DF4" : '#F8F9FD'};
    width: 260px;
    height: 48px;
    padding-left: 56px;
    padding-top: 15px;

    font-family: Pretendard, system-ui;
    font-size: 15px;
    color: ${(props) => props.Active ? "#FFFFFF" : '#3E4146'};
    font-weight: 500;
    line-height: 17.9px;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const CustomMenu = styled.div<{ Active?: boolean }>`
    width: 260px;
    height: 48px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    color: ${(props) => props.Active ? "#FFFFFF" : '#3E4146'};
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 14px;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;

    background-color: ${(props) => props.Active ? "#5D9DF4" : '#F0F3F8'};
`