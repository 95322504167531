import React from "react";
import styled from "styled-components";
import {Checkbox, Table} from "antd";
import {useTranslation} from "react-i18next";

export const TestTable = ({specialData}) => {

  const { t } = useTranslation();


  const dummy = [
    {
      id: 1,
      oid: 2,
      sid: 1,
      api: '/test/1',
      name: 'Test Spection Item',
      type: '-',
      area: 'card',
      amount: 30000,
      ld: '2024-08-30',
    },
    {
      id: 2,
      oid: 2,
      sid: 1,
      api: '/test/1',
      name: 'Test Spection Item2',
      type: '-',
      area: 'cash',
      amount: 30000,
      ld: '2024-08-30',
    },
    {
      id: 3,
      oid: 2,
      sid: 1,
      api: '/test/1',
      name: 'Test Spection Item3',
      type: '-',
      area: 'payment',
      amount: 30000,
      ld: '2024-08-30',
    },
  ]

  const columns = [
    {
      dataIndex: 'id',
      title: <TableHeader>No.</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'oid',
      title: <TableHeader>{t('currentAgency')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'sid',
      title: <TableHeader>{t('itemId')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'api',
      title: <TableHeader>{t('applicableAPI')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'name',
      title: <TableHeader>{t('itemName')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'type',
      title: <TableHeader>{t('ItemFormat')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'area',
      title: <TableHeader>{t('applicableTransaction')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'amount',
      title: <TableHeader>{t('applicableAmount')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
    {
      dataIndex: 'ld',
      title: <TableHeader>{t('date')}</TableHeader>,
      render: (value, data) => {
        return (
          <ValueItem>{value}</ValueItem>
        )
      }
    },
  ];


  return (
    <Oraganism>

      <StyledTable columns={columns} dataSource={specialData && dummy}/>

    </Oraganism>
  )
}

const Oraganism = styled.div`

`

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #BAC4DD99;
        color: #141C26CC;
        font-weight: bold;
        border: none;
    }

    .ant-table-thead > tr > th::before {
        display: none;
    }
`;

const TableHeader = styled.div`
    text-align: center;
`

const ValueItem = styled.div`
    text-align: center;
`