import React, {useEffect} from 'react';
import styled from "styled-components";
import {Select, theme} from "antd";
import {ReactComponent as DownArrow} from "../../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {ReactComponent as UpArrow} from "../../../../assets/backOffice/UtilIcon/UpArrow.svg";
import {useLocation} from "react-router-dom";
import {setFilterState} from "../../../../../utils/filterCash";


export const TimeSelectItem = ({title, value='', setValue, itemList = [{name: ''}], icon, width, isReset }:any) => {
  const location = useLocation();
  const { pathname } = location;
  const current = pathname.split('/admin')[1] || '';
  const FILTER_KEY = `${current}_timeItemValue`

  useEffect(() => {
    if(isReset){
      setValue('Today')
      setFilterState(FILTER_KEY, 'Today');
    }
  }, [isReset]);


  const handleChange = (value) => {
    setValue(value);
    setFilterState(FILTER_KEY, value);
  }

  return(
    <Atom>

      {title && <Title>{title}</Title>}

      {/*{icon && <IconSVG as={icon} />}*/}

      <StyledSelect
        style={{width}}
        value={value}
        onChange={(e:string) => handleChange(e)}
        suffixIcon={<ArrowSVG as={DownArrow} />}
      >
        {/*<StyledOption value={'all'}>All</StyledOption>*/}
        {itemList?.map(it => (
          <StyledOption value={it.name}>{it.name}</StyledOption>
        ))}
      </StyledSelect>
    </Atom>
  )
}

const Atom = styled.div`

`

const Title = styled.div`
    color: #6A6B70;
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    margin-bottom: 4px;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const IconSVG = styled.svg`
    width: 20px;
    position: absolute;
    z-index: 1;
    margin-top: 12px;
    margin-left: 18px;
`

const StyledSelect = styled(Select)`
    width: 160px;
    height: 44px;
    box-shadow: 0px 1px 10px 0px #0000001F;
`

const StyledOption = styled(Select.Option)`
    color: #6A6B70;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.09px;
    font-family: Pretendard, sans-serif;
    padding-left: 100px;

`