import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {apiError, checkEmail} from 'utils/ExpensiveFunction'
import ErrorHandling from './ErrorHandling'
import API from 'utils/api'
import {useToken, useUser} from 'app/hooks'
import styled from 'styled-components'
import {adminPageLogo} from 'app/constants/IMAGES'
import {useHistory} from 'react-router-dom'
import {message} from 'antd'
import backOfficeLogo from '../../../../assets/backOffice/backOfficeLogo.jpeg'


import Select, {SingleValue, StylesConfig} from 'react-select';
import moment from 'moment-timezone';

import '../../../../BackOffice/assets/font.css'
import languageImage from '../../../../assets/images/languageImage.jpg'
import {px} from 'framer-motion'
import {setUserInfoItem} from "../../../../utils/localUser";
import {setLocalCurrency, setLocalLang} from "../../../../utils/localLangCurrency";


interface TimezoneOption {
    value: string;
    label: string;
    offset: number;
}

const timeOptions: TimezoneOption[] = [
    {value: 'UTC+8', label: 'UTC+8', offset: +8},
    {value: 'UTC+7', label: 'UTC+7', offset: +7},
    {value: 'UTC+4', label: 'UTC+4', offset: +4},
    {value: 'UTC+1', label: 'UTC+1', offset: +1},
    {value: 'UTC+0', label: 'UTC+0', offset: 0},
    {value: 'UTC-3', label: 'UTC-3', offset: -3},
    {value: 'UTC-4', label: 'UTC-4', offset: -4},
    {value: 'UTC-5', label: 'UTC-5', offset: -5}
];

const currencyOptions = [
    {value: 'USD', label: 'USD'},
    {value: 'KRW', label: 'KRW'},
    {value: 'CNY', label: 'CNY'},
]

interface CountryOption {
    value: string;
    label: string;
    code: string;
}

const options: CountryOption[] = [
    {value: 'EN', label: 'EN - English', code: 'en'},
    {value: 'KR', label: 'KR - Korean', code: 'kr'},
    {value: 'CN', label: 'CN - Chinese', code: 'cn'}
];

const customStyles: StylesConfig<CountryOption, false> = {
    control: (provided) => ({
        ...provided,
        //border: '2px solid #007bff',
        borderRadius: '0px',
        padding: '0px',
        width: '285px',
        height: '44px'
    }),
    option: (provided, state) => ({
        ...provided,
        // backgroundColor: state.isFocused ? '#007bff' : state.isSelected ? '#0056b3' : undefined,
        //color: state.isFocused || state.isSelected ? 'white' : undefined,
    }),
};

const customSingleValue = ({data}: { data: CountryOption }) => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
        justifyContent: 'space-between',
        flex: '1 0 0',
        alignSelf: 'stretch'
    }}>
        <img
            src={`https://flagcdn.com/w20/${data.code === 'en' ? 'us' : data.code}.png`}
            alt={data.label}
            style={{
                display: 'flex',
                alignItems: 'center',
                width: '20px',
                height: '15px',
                marginRight: '8px',
                marginTop: '-28px'
            }}
        />
        <p style={{marginTop: '-28px',}}>{data.label}</p>
    </div>
);

const LoginForm = ({setLoginSuccess}) => {
    const {  t, i18n } = useTranslation();

    const [error, setError] = useState<string>('')
    const [success, setSuccess] = useState<string>('')
    const {mutate} = useToken()
    const {mutate: usernameMutate} = useUser()
    const navigate = useHistory()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [lgSelected, setLgSelected] = useState<CountryOption>(options[0]); // 기본값을 미국으로 설정

    const [selectedOption, setSelectedOption] = useState<TimezoneOption | null>(timeOptions[0]);
    const [selectedCurrency, setSelectedCurrency] = useState(currencyOptions[0]);



    const timeHandleChange = (option: SingleValue<TimezoneOption>) => {
        setSelectedOption(option as TimezoneOption);
    };

    // 동작확인용
    //  const getCurrentTime = (offset: number) => {
    //  	return moment().utcOffset(offset).format('YYYY-MM-DD HH:mm:ss');
    //  };


    const lgHandleChange = (option: SingleValue<CountryOption>) => {
        setLgSelected(option as CountryOption);
    };

    const onChangeEmail = e => {
        setEmail(e.target.value)
    }

    const onChangePassword = e => {
        setPassword(e.target.value)
    }


    // BO-001-001 [로그인]
    const handleSubmit = async () => {
        // mutate('test')
        // navigate.push('/admin')

        setLocalCurrency(selectedCurrency?.value)
        if(lgSelected.value === 'EN'){
            setLocalLang('EN');
        } else if(lgSelected.value === 'KR') {
            setLocalLang('KR');
        }else {
            setLocalLang('CN');
        }

        const res = checkEmail(email)
        if (typeof res === 'string') return setError(res)

        try {
            const res = await API.send('POST', '/back-office/Account/SignIn', null, {
                un: email,
                pw: password,
                cc: selectedCurrency?.value,
                utcto: selectedOption?.value,
                oc: '' // 소속 에이전시 정보를 어디서?
            })

            if (res.dt) {
                mutate(res?.dt?.sstk);
                usernameMutate(res?.dt?.un);
                setUserInfoItem(res.dt);
                // message.success('로그인 성공!');
                navigate.push('/admin');
            } else {
                const errorResult = apiError(t, res.err)
                setError(t(errorResult))
            }
        } catch (e) {
            console.log(e, 'ERROR LOGIN')
        }
    }


    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        // console.log('i18n.resolvedLanguage', i18n.resolvedLanguage);
    };

    useEffect(() => {
        console.log('lgSelected', lgSelected)

        if(lgSelected.value === 'EN'){
            changeLanguage('en');
        } else if(lgSelected.value === 'KR') {
            changeLanguage('ko');
        }else {
            changeLanguage('cn');
        }

    }, [lgSelected]);


    return (
        <>
            <LoginWrap>
                <LoginLogoImg src={backOfficeLogo} alt={'LoginLogoImg_'}/>
                <LoginLogoText>BACK OFFICE</LoginLogoText>
                {error?.length > 3 ? <ErrorHandling success={success} error={error}/> : null}
                <LoginInputWrap>
                    <LoginWrapper>
                        <LoingInputText>Username*</LoingInputText>
                        <LoginInput placeholder="Please Enter" onChange={onChangeEmail}></LoginInput>
                    </LoginWrapper>

                    <LoginWrapper>
                        <LoingInputText>Password*</LoingInputText>
                        <LoginInput
                            type="password"
                            placeholder="Please Enter"
                            onChange={onChangePassword}
                            maxLength={128}></LoginInput>
                    </LoginWrapper>

                </LoginInputWrap>
                <LoginSelectBoxWrap>
                    <LgSelectBoxGroupWrap>
                        <LgImg><img style={{width: '24px', height: '21.818px'}} alt={'images...'}
                                    src={languageImage}></img>
                        </LgImg>

                        <LgSelectBox
                            options={options}
                            value={lgSelected}
                            onChange={lgHandleChange}
                            //placeholder="Select a country"
                            components={{SingleValue: customSingleValue}}
                            styles={customStyles}
                            //classNamePrefix="country-select"
                        />

                    </LgSelectBoxGroupWrap>

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 14, gap: 10, width: '100%'}}>
                        <TimeSelect
                          options={timeOptions}
                          value={selectedOption}
                          onChange={timeHandleChange}
                          //placeholder="시간대를 선택하세요"
                          //classNamePrefix="timezone-select"
                        />

                        <CurrencySelect
                          options={currencyOptions}
                          value={selectedCurrency}
                          onChange={e => setSelectedCurrency(e as any)}
                        />
                    </div>


                </LoginSelectBoxWrap>


                <LoginBottomWrap>
                    <LoginButton
                        onClick={() => {
                            handleSubmit()
                        }}>
                        Login
                    </LoginButton>
                </LoginBottomWrap>

                <VersionInfoWrap>
                    <VersionInfo>
                        @2018-2024 All Rights Reserved.<br/>
                        (Version 3.1.13.9 l 6.1.0.34) - db 조회

                    </VersionInfo>
                </VersionInfoWrap>
            </LoginWrap>

        </>
    )

}


export default React.memo(LoginForm)


const LgSelectBoxGroupWrap = styled.div`
    display: flex;
    width: 350px;
    height: 44px;
    padding-right: 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`
const LgImg = styled.div`
    display: flex;
    padding: 0px 20px;
    /*flex-direction: column;*/
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-right: 1px solid #D8D8D8;
`
const LgSelectBoxWrap = styled.div`
    display: flex;
    padding-left: 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
`
const LgSelectBox = styled(Select)`

    color: #3E4146;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

const TimeSelect = styled(Select)`

    // display: flex;
    // width: 124px;
    // height: 44px;
    // padding: 0px 8px 0px 16px;
    // justify-content: space-between;
    // align-items: center;	

    border-radius: 4px;
    //border: 1px solid #D8D8D8;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    width: 40%;
`

const CurrencySelect = styled(Select)`
    border-radius: 4px;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    width: 60%;
`


const LoginWrap = styled.div`
    font-family: 'Pretendard', system-ui;
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 430px;
    padding-top: 50px;
    padding-bottom: 26px;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* main */

    border: 1px solid #F1F1F1;
    background-color: #FAFAFA;
`

const LoginLogoImg = styled.img`
    width: 120px;
    margin-bottom: 5px;
`

const LoginLogoText = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 31.03px;
    color: #121926;
    font-family: Pretendard, system-ui;
    margin-bottom: 70px;
`

const LoginInputWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`

const LoginWrapper = styled.div`
    width: 364px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const PasswordWrapper = styled.div`

`

const LoingInputText = styled.div`
    color: #6A6B70;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-bottom: 8px;
`

const LoginSelectBoxWrap = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 60px;
`

const LoginInput = styled.input`
    /* input */

    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;

    width: 100%;
    height: 100%;
    max-width: 360px;
    max-height: 50px;
    background-color: #fff;

    /* stroke */
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);


    font-size: 16px;
    /* color: #800F09 !important; */
    font-weight: 500;
    font-family: 'nanumSquareNeo';


    &::placeholder {
        color: #CBCACF;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`

const LoginSelectBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;

    width: 170px;
    height: 44px;
    padding: 0px 8px 0px 16px;
    justify-content: space-between;
    align-items: center;

    border-radius: 4px;
    border: 1px solid #D8D8D8;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`

const LoginBottomWrap = styled.div`
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    width: 100%;
    height: 100%;
    max-width: 360px;
    max-height: 104px;

    /* Inside auto layout */

    flex: none;
    order: 3;
    flex-grow: 0;
`

const LoginButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 350px;
    padding: 12px 10px;
    gap: 10px;

    background: #5D9DF4;
    border-radius: 4px;
    box-shadow: 0px 1px 10px 0px rgba(93, 157, 244, 0.50);

    border: none;
    flex: none;
    order: 1;
    flex-grow: 0;

    color: #FFF;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

const VersionInfoWrap = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 16px;

    width: 100%;
    height: 100%;
    max-width: 360px;
    max-height: 104px;

    /* Inside auto layout */

    flex: none;
    order: 4;
    flex-grow: 0;

`
const VersionInfo = styled.p`

    color: #A8A8A8;
    text-align: center;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

