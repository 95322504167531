import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Select, theme} from "antd";
import {ReactComponent as DownArrow} from "../../../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {ReactComponent as UpArrow} from "../../../../assets/backOffice/UtilIcon/UpArrow.svg";
import {useLocation} from "react-router-dom";
import {getFilterState, setFilterState} from "../../../../../../utils/filterCash";
import {getLocalLang} from "../../../../../../utils/localLangCurrency";




export const SelectItem = ({width, item, isReset, setJsonFilter, jsonFilter }:any) => {
  const location = useLocation();
  const { pathname } = location;
  const current = pathname.split('/admin')[1] || '';
  const FILTER_KEY = `${current}_${item?.name}`
  const lang = getLocalLang()
  const [filterValue, setFilterValue] = useState(getFilterState(FILTER_KEY));

  useEffect(() => {
    if(isReset){
      setFilterValue('All')
      setFilterState(FILTER_KEY, 'All');
    }
  }, [isReset]);


  const handleChange = (value) => {
    setFilterValue(value);
    setFilterState(FILTER_KEY, value);

    const newItem = jsonFilter;
    setJsonFilter({...newItem, [item?.name]: value})
  }

  return(
    <Atom>

      {item?.name && <Title>{lang === 'EN' ? item?.name : lang === 'KR' ? item?.koName : item?.name}</Title>}

      {/*{icon && <IconSVG as={icon} />}*/}

      <StyledSelect
        style={{width}}
        value={filterValue}
        onChange={(e:string) => handleChange(e)}
        suffixIcon={<ArrowSVG as={DownArrow} />}
      >
        {/*<StyledOption value={'all'}>All</StyledOption>*/}
        {item?.items?.map(it => (
          <StyledOption value={it}>{it}</StyledOption>
        ))}
      </StyledSelect>
    </Atom>
  )
}

const Atom = styled.div`

`

const Title = styled.div`
    color: #6A6B70;
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    margin-bottom: 4px;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const IconSVG = styled.svg`
    width: 20px;
    position: absolute;
    z-index: 1;
    margin-top: 12px;
    margin-left: 18px;
`

const StyledSelect = styled(Select)`
    width: 160px;
    height: 44px;
    box-shadow: 0px 1px 10px 0px #0000001F;
`

const StyledOption = styled(Select.Option)`
    color: #6A6B70;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.09px;
    font-family: Pretendard, sans-serif;
    padding-left: 100px;

`