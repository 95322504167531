import styled from "styled-components";
import {MobileCalVW} from "../../components/CalVW";

// ** TopBox.tsx =====
export const TopBoxWrapper = styled.div<{IsMobile?:boolean}>`
  width: 100%;
  height: ${(props) => props.IsMobile ? MobileCalVW(150): '150px'};
  background-color: #fff;
  padding: ${(props) => props.IsMobile ? MobileCalVW(28): '28px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 8px 2px #74747476;
`
export const InnerWrapper = styled.div<{IsMobile?:boolean}>`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex-shrink: 0;
  border: 1px solid #D8D8D8;
  background: #FFF;
`
export const InnerTitleWrapper = styled.div<{IsMobile?:boolean}>`
  display: flex;
  height: 80px;
  width: 100%;
  padding: 10px 28px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-bottom: 1px solid #D8D8D8;
`
export const InnerTitle = styled.p<{IsMobile?:boolean}>`
  color: #121926;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const InnerVersionWrapper = styled.div<{IsMobile?:boolean}>`
display: flex;
align-items: flex-end;
gap: 28px;

`
export const InnerVersion = styled.p<{IsMobile?:boolean}>`
color: #3E4146;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
`

export const InnerVersionTime = styled.p<{IsMobile?:boolean}>`
color: #A8A8A8;

font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
`

export const InnerContentWrapper = styled.div<{IsMobile?:boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 28px;

`


export const InnerContentTitle = styled.p<{IsMobile?:boolean}>`
color: #5D9DF4;
font-family: Pretendard;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;`


export const InnerContent = styled.p<{IsMobile?:boolean}>`
color: #6A6B70;
font-family: Pretendard;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
white-space: pre-wrap;
`




export const TopBoxTitle = styled.p<{IsMobile?:boolean}>`
  color: #898989;
  font-size: ${(props) => props.IsMobile ? MobileCalVW(30): '16px' } ;
  font-weight: 600;
`
export const TopBoxData = styled.p<{IsMobile?:boolean}>`
  font-size: ${(props) => props.IsMobile ? MobileCalVW(60): '50px' };
  color: #010101;
  font-weight: 700;
`
export const TopBoxLeft = styled.div<{IsMobile?:boolean}>`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  border-right: ${(props) => props.IsMobile ? 'none' : '2px solid #e7e7e7' };
`
export const TopBoxRight = styled.div <{IsMobile?:boolean}>`
  flex: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.IsMobile ? MobileCalVW(50): '50px' };
`
export const TopBoxRightDataArea = styled.div<{IsMobile?:boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.IsMobile ? MobileCalVW(20): '20px'};
`
export const TopBoxRightData = styled.div<{IsMobile?:boolean}>`
  display: flex;
  align-items: center;
  gap: 30px;
  ${TopBoxData} {
    text-align: right;
    width: 100%;
    font-size: ${(props) => props.IsMobile ? MobileCalVW(40): '40px'};
  }
`
export const TopBoxBadge = styled.span<{IsMobile?:boolean}>`
  background-color: #010101;
  color: #fff;
  border-radius: ${(props) => props.IsMobile ? MobileCalVW(15): '12px'};
  min-width: ${(props) => props.IsMobile ? MobileCalVW(80): '50px'};
  text-align: center;
  font-size: ${(props) => props.IsMobile ? MobileCalVW(22): '13px'};
  padding: ${(props) => props.IsMobile ? MobileCalVW(6): '3px'};
`

// ** MidBox.tsx =====
export const MidBoxWrapper = styled.div<{IsMobile?:boolean}>`
  width: 100%;
  height: ${(props) => props.IsMobile ? MobileCalVW(700)  : '300px'};
  display: flex;
  flex-direction: ${(props) => props.IsMobile ? 'column' : 'row'};
  gap: ${(props) => props.IsMobile ? MobileCalVW(16) : '16px'};
  align-items: center;
`

export const MidBoxLeft = styled.div<{IsMobile?:boolean}>`
  flex: 1;
  width: ${(props) => props.IsMobile && '100%'};
  padding: ${(props) => props.IsMobile && MobileCalVW(28)};
  height: ${(props) => props.IsMobile ? MobileCalVW(300) : '300px'};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 8px 2px #74747476;
`
export const MidBoxMid = styled.div<{IsMobile?:boolean}>`
  position: relative;
  flex: 2;
  height: ${(props) => props.IsMobile ? MobileCalVW(300) : '300px'};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 8px 2px #74747476;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: ${(props) => props.IsMobile ? MobileCalVW(15) : '15px'} !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #df7777;
  }
  &::-webkit-scrollbar-track {
    background-color: #888;
  }
`
export const MidBoxRight = styled.div<{IsMobile?:boolean}>`
  flex: 1;
  height: ${(props) => !props.IsMobile && '100%'};
  display: flex;
  flex-direction: ${(props) => props.IsMobile ? 'row' : 'column'};
  gap: ${(props) => props.IsMobile ? MobileCalVW(16) : '16px'};
`
export const MidBoxRightBox = styled.div<{IsMobile?:boolean}>`
  height: ${(props) => props.IsMobile ? '' : '50%'};
  width: ${(props) => props.IsMobile ? MobileCalVW(345) : '100%'};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.IsMobile ? `${MobileCalVW(10)} ${MobileCalVW(30)}` : '10px 30px'};
  box-shadow: 2px 2px 8px 2px #74747476;
`
export const MidBoxRightBoxTitle = styled.p<{IsMobile?:boolean}>`
  color: #010101;
  font-size: ${(props) => props.IsMobile ? MobileCalVW(22) : '16px'};
  font-weight: 700;
  padding: ${(props) => props.IsMobile ? `${MobileCalVW(5)} ${MobileCalVW(12)}` : '5px 12px'};
`
export const MidBoxRightBoxData = styled.p<{IsMobile?:boolean}>`
  color: #898989;
  font-size: ${(props) => props.IsMobile ? MobileCalVW(20) : '15px'};
  border-top: 1px solid #e7e7e7;
  padding: ${(props) => props.IsMobile ? `${MobileCalVW(5)} ${MobileCalVW(12)}` : '5px 12px'};
  span {
    min-width: ${(props) => props.IsMobile ? MobileCalVW(65) : '65px'};
    display: inline-block;
  }
`

// ** CurrentDeposit.tsx =====
export const DepositTitle = styled.p<{IsMobile?:boolean}>`
  color: #010101;
  font-size: ${(props) => props.IsMobile ? MobileCalVW(30) : '16px'};
  font-weight: 500;
  padding: 0px 12px;
`
export const CurrentDepositWrapper = styled.div<{IsMobile?:boolean}>`
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 8px 2px #74747476;
  padding: ${(props) => props.IsMobile ? `${MobileCalVW(20)} ${MobileCalVW(12)}` : '20px 12px'};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.IsMobile ? MobileCalVW(16) : '16px'};
  .ant-table-content > * {
    font-family: 'Pretendard';
  }
  .ant-pagination {
    justify-content: center;
  }
`
export const GraphDisplay = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const GraphArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: #898989;
  font-weight: 600;
`
export const Graph = styled.div`
  width: 100%;
  border: 1px solid #e7e7e7;
`