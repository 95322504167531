import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'

const ErrorHandling = ({ success, error }) => {
	const { t } = useTranslation()

	return (
		<View style={{ alignItems: 'flex-start' }}>
			<Text size={12} style={{ color: success ? 'green' : 'red' }}>
				{success ? t(success) : t(error)}
			</Text>
		</View>
	)
}

export default ErrorHandling
