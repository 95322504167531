import styled from "styled-components";
import React, {useEffect, useState} from 'react';
import TestUser from '../../assets/backOffice/TopNav/TestUser.png'
import {useHistory} from "react-router-dom";
import {getUserInfoItem} from "../../utils/localUser";
import API from "../../utils/api";
import {useToken} from "../../app/hooks";
import {resetTabItem} from "../../utils/winTab";


export const UserInfoCard = ({isUserInfoCard}) => {
    const navigate = useHistory()
  const {data:token} = useToken()
  const [userInfoData, setUserInfoData] = useState({
    uid: '',
    un: 'PLUTO',
    fn: '',
    em: 'pluto@pgsoft.com',
    s: '',
    old: ''
  });

    const [version, setVersion] = useState<string>('3.1.13.9 l 6.1.0.34');


  useEffect(() => {
    if(isUserInfoCard) {
      postUserInfo();
      getSystemVersion();
    }
  }, [isUserInfoCard]);


  // 사용자 정보 읽기(after BO-001-001)
  const postUserInfo = async () => {
    const userInfo = getUserInfoItem();

    try {
      const res = await API.send('POST', '/back-office/Account/GetUser', token+'', {
        uid:userInfo?.uid
      })

      if (res.dt) {
        setUserInfoData(res.dt)
      } else {
        console.log('ERROR 사용자 정보 읽기', res?.err)
      }
    } catch (e) {
      console.log(e, 'ERROR 사용자 정보 읽기')
    }
  }


  // 시스템 버전 읽기(after BO-002-001)
  const getSystemVersion = async () => {
    try {
      const res = await API.send('GET', '/back-office/Version/GetVersion', token+'', {
      })

      if (res.dt) {
        setVersion(res?.dt?.version)
      } else {
        console.log('ERROR 시스템 버전 읽기', res?.err)
      }
    } catch (e) {
      console.log(e, 'ERROR 시스템 버전 읽기')
    }
  }

    return (
        <Component>
            <UserInfoWrapper>
                <UserImg src={TestUser}/>
                <UserInfoName>
                  {userInfoData?.un ? userInfoData.un : '-' }
                    <UserEmail>
                      {userInfoData?.em ? userInfoData.em : '-' }
                    </UserEmail>
                </UserInfoName>
            </UserInfoWrapper>

            <Line />

            <ResetPasswordButton>
                Reset Password
            </ResetPasswordButton>

            <LogoutButton onClick={() => {
              navigate.push('/admin/logout');
              resetTabItem();
            }}>
                Logout
            </LogoutButton>

            <VersionText>
                Version {version}
            </VersionText>
        </Component>
    )
}

const Component = styled.div`
    width: 346px;
    height: 310px;
    position: absolute;
    background-color: #FFFFFF;
    border: 1px solid #F1F1F1;
    top: 50px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 31px;
    border-radius: 4px;
    z-index: 9999;
`

const UserInfoWrapper = styled.div`
    display: flex;
    gap: 23.83px;
`

const UserImg = styled.img`
    width: 45px;
    height: 45px;
`

const UserInfoName = styled.div`
    color: #121926;
    font-family: Pretendard, system-ui;
    font-weight: 700;
    font-size: 20px;
`

const UserEmail = styled.div`
    color: #6A6B70;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    margin-top: 6px;
`

const Line = styled.div`
    border: 1px solid #F1F1F1;
    height: 1px;
    width: 100%;
    margin: 30px 0;
`

const ResetPasswordButton = styled.div`
    border: 1px solid #5D9DF4;
    background-color: #FFFFFF;
    width: 280px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    border-radius: 4px;
    cursor: pointer;

    color: #5D9DF4;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.05px;
`

const LogoutButton = styled.div`
    box-shadow: 0px 1px 10px 0px #5D9DF480;
    background-color: #5D9DF4;
    width: 280px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.05px;
`

const VersionText = styled.div`
    color: #A8A8A8;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.51px;
    margin-top: 16px;
`