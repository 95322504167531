import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Checkbox, Col, Row, Select, Input} from "antd";
import {ReactComponent as DownArrow} from "../../../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {ReactComponent as UpArrow} from "../../../../assets/backOffice/UtilIcon/UpArrow.svg";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {useLocation} from "react-router-dom";
import {getFilterState, setFilterState} from "../../../../../../utils/filterCash";
import {getLocalLang} from "../../../../../../utils/localLangCurrency";


export const CheckInputItem = ({ item, isReset, setJsonFilter, jsonFilter }:any) => {
  const location = useLocation();
  const { pathname } = location;
  const current = pathname.split('/admin')[1] || '';
  const FILTER_KEY = `${current}_${item?.name}`
  const lang = getLocalLang()

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(getFilterState(FILTER_KEY));


  useEffect(() => {
    if(isReset){
      setSelectedValue('All')
      setFilterState(FILTER_KEY, 'All');
    }
  }, [isReset]);


  const handleCheckboxChange = (e) => {
    const selectedItem = e.target.value;
    setSelectedValue(selectedItem);
    setFilterState(FILTER_KEY, selectedItem);

    const newItem = jsonFilter;
    setJsonFilter({...newItem, [item?.name]: selectedItem})

    setOpen(false);
  };


  return(
    <Atom>

      {item?.name && <Title>{lang === 'EN' ? item?.name : lang === 'KR' ? item?.koName : item?.name}</Title>}

      {/*{icon && <IconSVG as={icon} />}*/}

      <InputWrapper>
        <StyleInput
          value={selectedValue}
          placeholder="Select options"
          onClick={() => setOpen(!open)}
          readOnly
        >
        </StyleInput>
        {open && (
          <OpenInput>
            <Row gutter={[16, 16]}>
              {item?.items?.map((item, index) => (
                <Col span={6} key={index}>
                  <Checkbox
                    value={item}
                    onChange={handleCheckboxChange}
                    checked={selectedValue === item}
                  >
                    {item}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </OpenInput>
        )}
      </InputWrapper>

      {/*<StyledSelect*/}
      {/*  style={{width}}*/}
      {/*  value={value}*/}
      {/*  onChange={(e: string) => setValue(e)}*/}
      {/*  suffixIcon={<ArrowSVG as={DownArrow}/>}*/}
      {/*>*/}
      {/*  /!*<StyledOption value={'all'}>All</StyledOption>*!/*/}
      {/*  {itemList?.map(it => (*/}
      {/*    <StyledOption value={it.name}>{it.name}</StyledOption>*/}
      {/*  ))}*/}
      {/*</StyledSelect>*/}
    </Atom>
  )
}

const Atom = styled.div`
   
`

const Title = styled.div`
    color: #6A6B70;
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    margin-bottom: 4px;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const IconSVG = styled.svg`
    width: 20px;
    position: absolute;
    z-index: 1;
    margin-top: 12px;
    margin-left: 18px;
`

const StyledSelect = styled(Select)`
    width: 160px;
    height: 44px;
    box-shadow: 0px 1px 10px 0px #0000001F;
`

const StyledOption = styled(Select.Option)`
    color: #6A6B70;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.09px;
    font-family: Pretendard, sans-serif;
    padding-left: 100px;

`

const InputWrapper = styled.div`
    position: relative;
`

const StyleInput = styled(Input)`
    width: 160px;
    height: 44px;
    box-shadow: 0px 1px 10px 0px #0000001F;
`

const OpenInput = styled.div`
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 4px;
    margin-top: 2px;
    position: absolute;
    background-color: #FFFFFF;
    z-index: 999;
    width: 500px;
`