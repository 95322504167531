import React, {useState} from "react";
import styled from "styled-components";
import {RoundCheckbox} from "../../atoms/RoundCheckbox";
import {DatePicker, Input} from "antd";
import {ReactComponent as DownArrow} from "../../../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {ReactComponent as GreyClockIcon} from "../../../../../../assets/backOffice/UtilIcon/GreyClockIcon.svg";
import {ReactComponent as ViewStaticIcon} from "../../../../../../assets/backOffice/UtilIcon/ViewStaticIcon.svg";
import {ReactComponent as ExportIcon} from "../../../../../../assets/backOffice/UtilIcon/ExportIcon.svg";
import {ReactComponent as ResetIcon} from "../../../../../../assets/backOffice/UtilIcon/ResetIcon.svg";
import {SelectItem} from "../../atoms/SelectItem";
import {useHistory} from "react-router-dom";


export const BottomFilter = ({timeItemValue, setTimeItemValue}) => {
  const navigate = useHistory();
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const TimeItem = {
    itemList: [
      {name: 'Today'},
      {name: 'Yesterday'},
      {name: 'Last 7 Days'},
      {name: 'Last 30 Days'},
      {name: 'This Month'},
      {name: 'Last Month'},
    ],
    value: timeItemValue,
    setValue: setTimeItemValue,
    title: 'Feature Buy'
  }

  return (
    <Molecule>

      <ItemsWrapper>
        <LeftItemsWrapper>
          <StyleInput />

          <SearchButton>
            Search
          </SearchButton>
        </LeftItemsWrapper>

      </ItemsWrapper>

    </Molecule>
  )
}

const Molecule = styled.div`
    margin-top: 18px;
`

const ItemsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const LeftItemsWrapper = styled.div`
    display: flex;
    gap: 10px;
`

const RightItemsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`


const SearchButton = styled.div`
    width: 160px;
    height: 40px;
    box-shadow: 0px 1px 10px 0px #5D9DF480;
    background-color: #5D9DF4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    font-family: Pretendard, sans-serif;
`

const StyleInput = styled(Input)`
    box-shadow: 0px 1px 10px 0px #0000001F;
    height: 40px;
`