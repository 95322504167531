const LOCAL_CURRENCY = 'LOCAL@CURRENCY'
const LOCAL_LANG = 'LOCAL@LANG'



export const setLocalCurrency = (item) => {

  localStorage.setItem(LOCAL_CURRENCY, JSON.stringify(item));
}



export const getLocalCurrency = () => {
  return JSON.parse(localStorage.getItem(LOCAL_CURRENCY) || '')
}


export const setLocalLang = (item) => {

  localStorage.setItem(LOCAL_LANG, JSON.stringify(item));
}



export const getLocalLang = () => {
  return JSON.parse(localStorage.getItem(LOCAL_LANG) || '')
}


// 달러(USD) 표기로 전환하는 함수
export const formatToUSD = (amount, currency = 'USD') => {
  let convertedAmount;

  switch (currency) {
    case 'KRW':
      convertedAmount = amount / 1300; // 한화에서 달러로 변환
      break;
    case 'CNY':
      convertedAmount = amount / 7.2; // 위엔화에서 달러로 변환
      break;
    default:
      convertedAmount = amount; // 이미 달러인 경우
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(convertedAmount);
};

// 한화(KRW) 표기로 전환하는 함수
export const formatToKRW = (amount, currency = 'KRW') => {
  let convertedAmount;

  switch (currency) {
    case 'USD':
      convertedAmount = amount * 1300; // 달러에서 한화로 변환
      break;
    case 'CNY':
      convertedAmount = amount * 180; // 위엔화에서 한화로 변환
      break;
    default:
      convertedAmount = amount; // 이미 한화인 경우
  }

  return new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: 'KRW',
  }).format(convertedAmount);
};

// 위엔화(CNY) 표기로 전환하는 함수
export const formatToCNY = (amount, currency = 'CNY') => {
  let convertedAmount;

  switch (currency) {
    case 'USD':
      convertedAmount = amount * 7.2; // 달러에서 위엔화로 변환
      break;
    case 'KRW':
      convertedAmount = amount / 180; // 한화에서 위엔화로 변환
      break;
    default:
      convertedAmount = amount; // 이미 위엔화인 경우
  }

  return new Intl.NumberFormat('zh-CN', {
    style: 'currency',
    currency: 'CNY',
  }).format(convertedAmount);
};

