import styled from 'styled-components'

export const SectionContainer = styled.div`
	height: 100%;
	width: 100%;
	color: #ccc;
	@media only screen and (max-width: 968px) {
		margin-top: 60px;
	}
`

export const SectionWrap = styled.div`
	height: 100%;
	width: 100%;
	align-items: flex-start;
	justify-content: center;
	display: flex;
	background-color: transparent;
`
export const LoginWrapper = styled.div`
	height: 100%;
	width: 90%;
	border-radius: 10px;
	align-items: flex-start;
	justify-content: center;
	padding-top: 30px;
`

export const LoginSubContainer = styled.div`
	align-items: center;
	justify-content: flex-start;
	padding: 20px;
`

export const ForgotPassword = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 5px;
`
export const TermOfService = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 250px;
`

export const LoginWidth = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 20px;
`


export const Divider = styled.div<{ color: string }>`
	flex: 1;
	height: 1px;
	background-color: ${props => props.color};
`

export const TabContainer = styled.div`
	margin-bottom: 8px;
	display: flex;
	justify-content: space-around;
	align-items: center;
`


export const BtnContainer = styled.div<{
	bgColor?: string
	boxShadow?: string
}>`
	color: white;
	padding: 0;
	border-radius: 5px;
	outline: 0;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	cursor: pointer;
	justify-content: center;
	width: 100%;
	box-shadow: ${props => props.boxShadow};
	transition: ease background-color 250ms;
	&:hover {
		opacity: 0.8;
	}
	&:disabled {
		cursor: default;
		opacity: 0.7;
	}
`
