import React, {useState} from 'react'
import styled from "styled-components";
import {ReactComponent as HideFilterIcon} from "../../../../assets/backOffice/UtilIcon/HideFilterIcon.svg";
import {ReactComponent as CurrencyIcon} from "../../../../assets/backOffice/UtilIcon/CurrencyIcon.svg";
import {ReactComponent as UpArrow} from "../../../../assets/backOffice/UtilIcon/UpArrow.svg";
import {ReactComponent as DownArrow} from "../../../../assets/backOffice/UtilIcon/DownArrow.svg";
import {Display} from "../../Main/styles";
import {TestFilter} from "./organisms/TestFilter";
import {TestTable} from "./organisms/TestTable";
import {
  InnerContent,
  InnerContentTitle,
  InnerContentWrapper,
  InnerTitle,
  InnerTitleWrapper,
  InnerVersion,
  InnerVersionTime,
  InnerVersionWrapper, InnerWrapper
} from "../../Home/styles";

export const Alarm = React.memo(() => {
  const [content, setContent] = useState( ['* Currency Convertor Box - choose which currency that you want to replace\n', '* Operator Report - remove sorting for player count and new player count\n', '* New Pages : Operator - Operator Hourly Hands Summary\n','* Free Game & Bonus Enhancement - name allowed “I” character\n'] );

  return(
    <Display>

      <InnerWrapper>
        <InnerTitleWrapper>
          <InnerTitle>Change Log</InnerTitle>
        </InnerTitleWrapper>
        <InnerContentWrapper>
          <InnerVersionWrapper>
            <InnerVersion>Version2.1.9</InnerVersion><InnerVersionTime> 10 October 2019 </InnerVersionTime>
          </InnerVersionWrapper>
          <InnerContentTitle>
            Features
          </InnerContentTitle>
          <InnerContent>
            {content}
          </InnerContent>
        </InnerContentWrapper>
      </InnerWrapper>

    </Display>
  )
})


const TopUtilWrapper = styled.div`
    display: flex;
    gap: 12px;
`

const UtilItem = styled.div`
    width: 160px;
    height: 40px;
    background-color: #E4E7EC;
    padding-left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    
    color: #141C26;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
`

const UtilIconSVG = styled.svg`
    width: 20px;
    margin-right: 6px;
`

const ArrowSVG = styled.svg`
    margin-left: 11px;
`
